const utmParams = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content'];

const prefixMediums = {
  EM: ['email'],
  SEM: ['search'],
  DIS: ['display'],
  AFF: ['affiliate'],
  CON: ['content']
};

export function loadUtm() {
  try {
    const utm = window.sessionStorage.getItem('utmParams');
    return utm && JSON.parse(utm);
  } catch (e) {
    window.console.error('Failed to parse stored UTM data', e);
    return null;
  }
}

export function getUtm() {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const parsedParams = {};
    queryParams.forEach((value, key) => {
      if (utmParams.includes(key)) {
        parsedParams[key.substring(4)] = value;
      }
    });

    const existingParams = loadUtm() || {};
    const utm = { ...existingParams, ...parsedParams };
    window.sessionStorage.setItem('utmParams', JSON.stringify(utm));

    return utm;
  } catch (e) {
    window.console.error('Failed to persist UTM data', e);
    return null;
  }
}

function getPrefixForMedium(medium) {
  const [prefix] = Object.entries(prefixMediums)
    .find(([_, mediums]) => mediums.includes(medium)) || []; // eslint-disable-line no-unused-vars

  return prefix;
}

export function cidForSession() {
  const utm = getUtm();
  if (!utm || !Object.keys(utm).length) {
    return '';
  }

  const { medium = 'nomedium', campaign = 'nocampaign', source = 'nosource', content = 'nocontent', term = 'noterm' } = utm;
  const prefix = getPrefixForMedium(medium);
  const mediumTag = !prefix ? `-${medium}` : '';

  // default to SEM incase of bad mapping since it is least likely to be used
  return `${prefix || 'SEM'}-CARVANA-${campaign}-${source}-${content}-${term}${mediumTag}`;
}
