/* eslint-disable max-len */
export function Envelope() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'>
      <path stroke='#00619E' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M14 20.417V18a3 3 0 0 1 3-3h32a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H17a3 3 0 0 1-3-3v-2.417' />
      <path stroke='#00AED9' strokeLinecap='round' strokeWidth='2' d='M15.559 24H8m7.559 8H8' />
      <path stroke='#00619E' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m14 16 17.846 15.434a1 1 0 0 0 1.308 0L51 16' />
      <path stroke='#00AED9' strokeLinecap='round' strokeWidth='2' d='M15.895 28H3' />
      <path stroke='#00619E' strokeLinecap='round' strokeWidth='2' d='m39 28 12 12M27 28 15 40' />
    </svg>
  );
}

export default Envelope;
