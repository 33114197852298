import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import { text } from '@carvana/helios-styles';
import { flexAlignVerticalContent } from '@src/styles/mixins';

const TimerText = ({ label, time, center, color, labelColor }) => (
  <Container $center={ center }>
    <Label $color={ labelColor }>{label}</Label>
    <Text $color={ color }>
      {time.hours} : {time.minutes} : {time.seconds}
    </Text>
  </Container>
);

const Container = styled.div`
  ${({ $center }) => flexAlignVerticalContent($center ? 'center' : 'flex-start', 'center')};
`;

const Label = styled.p`
  color: ${({ $color }) => $color};
  ${typeStyle('body-xs')};
`;

const Text = styled.p`
  color: ${({ $color }) => $color};
  ${typeStyle('header-m')};
`;

TimerText.defaultProps = {
  center: false,
  labelColor: text.default,
};

TimerText.propTypes = {
  label: PropTypes.string,
  time: PropTypes.shape({
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string
  }),
  center: PropTypes.bool,
  color: PropTypes.string,
  labelColor: PropTypes.string
};

export default TimerText;
