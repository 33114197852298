const ChipClose = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
      <path
        fill='currentColor'
        d='M12.35 4.35a.5.5 0 0 0-.7-.7l.7.7Zm-8.7 7.3a.5.5 0 0 0 .7.7l-.7-.7Zm8-8-8 8 .7.7 8-8-.7-.7Z'
      />
      <path
        fill='currentColor'
        d='M11.65 12.35a.5.5 0 0 0 .7-.7l-.7.7Zm-7.3-8.7a.5.5 0 1 0-.7.7l.7-.7Zm8 8-8-8-.7.7 8 8 .7-.7Z'
      />
    </svg>
  );
};

export default ChipClose;
