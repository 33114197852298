/* eslint-disable max-len */
import PropTypes from 'prop-types';

const CheckOutline = ({ color }) => {
  return (
    <svg role='img' aria-hidden='true' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='9' stroke={ color } strokeWidth='2' />
      <path d='M8 13.1212C8.93726 14.1153 10.4 15.6667 10.4 15.6667L16 9' stroke={ color } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

CheckOutline.defaultProps = {
  color: '#666666'
};

CheckOutline.propTypes = {
  color: PropTypes.string
};

export default CheckOutline;
