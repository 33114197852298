/* eslint-disable max-len */
export default function SadCar({ haloColor = '#00AED9', mainColor = '#00619E', size = 'lg' }) {
  const sizeMap = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    small: 'sm',
    medium: 'md',
    large: 'lg'
  };
  const iconSize = sizeMap[size];

  if (iconSize === 'sm') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'>
        <path stroke={ mainColor } strokeLinejoin='round' strokeWidth='2' d='M20.586 9.689h-9.243a1 1 0 0 0-.614.21l-4.15 3.23a1.13 1.13 0 0 1-.167.105c-.653.345-1.499.955-3.412.955-1.6 0-2 .667-2 1v4c0 .8.667 1 1 1h28a1 1 0 0 0 1-1v-1.5c0-1.2-.5-2-2-2.5l-3.25-.929a1 1 0 0 1-.433-.254l-4.024-4.024a1 1 0 0 0-.707-.293z' />
        <circle cx='9.5' cy='20.189' r='2.5' fill='#fff' stroke={ mainColor } strokeWidth='2' />
        <circle cx='22.5' cy='20.189' r='2.5' fill='#fff' stroke={ mainColor } strokeWidth='2' />
        <ellipse cx='16' cy='4.189' stroke={ haloColor } strokeWidth='2' rx='6' ry='1.5' />
        <path stroke={ mainColor } strokeLinecap='round' strokeWidth='2' d='M20 27.689c-.75-1.188-2.26-2-4-2-1.74 0-3.25.812-4 2' />
      </svg>
    );
  }

  if (iconSize === 'md') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'>
        <ellipse cx='31' cy='11' stroke={ haloColor } strokeWidth='2' rx='7' ry='2' />
        <path stroke={ mainColor } strokeLinecap='round' strokeWidth='2' d='M37.128 44.557a5.638 5.638 0 0 0-5-3 5.638 5.638 0 0 0-5 3' />
        <path stroke={ mainColor } strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M37 34h-9.939M20 34h-7.144a.856.856 0 0 1-.856-.856v-2.157H6m1-2.996h5v-1.375a1 1 0 0 1 .836-.986l5.927-.983a.856.856 0 0 0 .394-.175l1.586-1.265.88-.7.88-.702.88-.701.88-.701 1.527-1.216a.856.856 0 0 1 .533-.187h10.77a.86.86 0 0 1 .56.208l7.464 6.447a.856.856 0 0 0 .297.167l6.4 2.064A1.712 1.712 0 0 1 53 29.515v3.63a.856.856 0 0 1-.856.855H44' />
        <circle cx='23.5' cy='34.5' r='3.5' stroke={ mainColor } strokeWidth='2' />
        <circle cx='40.5' cy='34.5' r='3.5' stroke={ mainColor } strokeWidth='2' />
        <circle cx='4' cy='28' r='1' fill={ mainColor } />
      </svg>
    );
  }

  return (
    <svg width='104' height='104' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='54.699' cy='24.794' rx='12' ry='3' stroke={ haloColor } strokeWidth='2' />
      <path d='M62.7 76.794c-1.313-2.376-3.955-4-7-4-3.047 0-5.689 1.624-7 4' stroke={ mainColor } strokeWidth='2' strokeLinecap='round' />
      <path d='M62.5 62H48m-13 0H23.856a.856.856 0 0 1-.856-.856V58m0 0H11m12 0v-2m-9-3.94h9V49.4a1 1 0 0 1 .847-.988l10.797-1.67a.856.856 0 0 0 .403-.176l2.607-2.08.696-.555M75 62h13.144a.856.856 0 0 0 .856-.856v-7.388c0-.747-.485-1.408-1.198-1.633l-11.212-3.53a.855.855 0 0 1-.297-.164L64.257 38.204a.856.856 0 0 0-.554-.204H46.28a.856.856 0 0 0-.507.167l-2.55 1.876-1.173.806a.856.856 0 0 1-.485.151H31' stroke={ mainColor } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <circle cx='41.727' cy='62.794' r='6' stroke={ mainColor } strokeWidth='2' />
      <circle cx='68.699' cy='62.794' r='6' stroke={ mainColor } strokeWidth='2' />
      <path d='M9.7 51.794h-3M26.7 40.794h-6' stroke={ mainColor } strokeWidth='2' strokeLinecap='round' />
      <circle cx='41.699' cy='62.794' r='2' stroke={ mainColor } strokeWidth='2' />
      <circle cx='68.699' cy='62.794' r='2' stroke={ mainColor } strokeWidth='2' />
    </svg>
  );
}
