/* eslint-disable max-len */
import React from 'react';

const ArrowLeft = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='m10.25 6-6.5 6m0 0 6.5 6m-6.5-6h16.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default ArrowLeft;
