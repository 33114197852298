/* eslint-disable max-len */
import PropTypes from 'prop-types';

const Barcode = ({ size, color }) => (
  <svg width={ size } height={ size } viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill={ color } fillRule='evenodd' clipRule='evenodd' d='M10.6083 12.5218V11.8135H12.6888V9.67981H13.4V12.5218H10.6083ZM10.885 10.5324V2.85902H11.4471V10.5324H10.885ZM12.6888 1.57293H10.6083V0.869629H13.4V3.71162H12.6888V1.57293ZM9.63097 2.85902H10.4701V10.5324H9.63097V2.85902ZM7.81541 2.85902H9.35222V10.5324H7.81541V2.85902ZM6.28307 2.85902H7.11791V10.5324H6.28307V2.85902ZM5.44249 2.85902H5.72362V10.5324H5.44249V2.85902ZM4.32764 2.85902H5.16263V10.5324H4.32764V2.85902ZM1.95417 2.85902H3.76763V10.5324H1.95417V2.85902ZM0.713522 3.71162H0V0.869629H2.79167V1.57293H0.713522V3.71162ZM0.713522 11.8135H2.79167V12.5218H0V9.67981H0.713522V11.8135Z' />
  </svg>
);

Barcode.defaultProps = {
  size: 14,
  color: '#00AED9'
};

Barcode.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Barcode;
