/* eslint-disable max-len */
const DocReview = () => (
  <svg width='56' height='56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='m43 44 3.122 3.122' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' />
    <circle cx='38' cy='39' r='6' stroke='#00AED9' strokeWidth='2' />
    <path d='M25 41H13.997A2.997 2.997 0 0 1 11 38V8a3 3 0 0 1 3-3h18.757a3 3 0 0 1 2.122.879l5.242 5.242c.563.563.879 1.323.879 2.12V25' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    <path d='M33 5v6h2V5h-2zm1.998 8H41v-2h-6.002v2zM33 11c0 1.102.891 2 1.998 2v-2c-.001 0-.001 0 0 0H35v.002V11h-2z' fill='#00619E' />
    <path stroke='#00619E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' d='M17 18h18m-18 6h18m-18 6h8' />
  </svg>
);

export default DocReview;
