import { useState } from 'react';
import styled from 'styled-components';
import Modal from '@carvana/showroom-modal';
import { typeStyle } from '@carvana/showroom-css-theme';
import { flexAlignVerticalContent } from '../styles/mixins';
import Stopwatch from '../components/icons/Stopwatch';

import '@carvana/showroom-modal/index.css';

const useTimeoutModal = () => {
  const [open, setOpen] = useState(false);
  const closeTimeoutModal = () => setOpen(false);
  const openTimeoutModal = () => setOpen(true);

  const ModalWrapper = ({ children, headerText, titleText, bodyText, ...props }) => (
    <Modal { ...props } headerText={ headerText } isOpen={ open } closeModal={ closeTimeoutModal }>
      <ModalContent>
        <IconContainer>
          <Stopwatch />
        </IconContainer>
        <TitleText>{ titleText }</TitleText>
        <BodyText>{ bodyText }</BodyText>
        {children}
      </ModalContent>
    </Modal>
  );

  return { TimeoutModal: ModalWrapper, closeTimeoutModal, openTimeoutModal };
};

const ModalContent = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
  margin: 20px 0;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;
`;

const TitleText = styled.p`
  ${typeStyle('header-m')}
  color: var(--cvna-color-blue-7);
  margin-bottom: 16px;
`;

const BodyText = styled.p`
  ${typeStyle('body-s')}
  color: var(--cvna-color-grey-5);
  text-align: center;
  max-width: 400px;
  margin-bottom: 20px;
`;

export default useTimeoutModal;
