import isSSR from './isSSR';

const ZE = 'zE';
const ZOPIM = '$zopim';

export const zendeskExists = () => getZendeskSDKType() !== null;

export const showChat = () => {
  if (getZendeskSDKType() === ZE) {
    window.zE('messenger', 'open');
  }

  if (getZendeskSDKType() === ZOPIM) {
    window.$zopim.livechat?.window?.show();
  }
};

export const getZendeskSDKType = () => {
  if (isSSR()) {
    return null;
  }

  if (window.zE) {
    return ZE;
  }

  if (window.$zopim) {
    return ZOPIM;
  }

  return null;
};
