import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { text, icon } from '@carvana/helios-styles';
import useSaleEndsIn from '@hooks/useSaleEndsIn';
import { toSeconds } from '@src/utils/time';
import isSSR from '@src/utils/isSSR';
import { flexAlignHorizontalContent, mq, LARGE_MOBILE, flexAlignVerticalContent } from '@src/styles/mixins';
import {
  states,
  getTimerState,
  getTimeRemaining,
  getColorFromState,
  getLabelFromState
} from '../utils';
import TimerLabel from '../TimerLabel';
import TimerText from '../TimerText';
import ProgressBar from './ProgressBar';

const ProgressBarCountdown = ({ listing, showTimer }) => {
  const client = useApolloClient();
  const { start, currentTime } = useSaleEndsIn();
  const { startTime, endTime, isLive } = listing || {};
  const startOfSale = Date.parse(startTime);
  const endOfSale = Date.parse(endTime);

  const isTimerLive = currentTime >= toSeconds(startOfSale);

  useEffect(() => {
    if (isTimerLive) {
      client.cache.modify({
        id: client.cache.identify(listing),
        fields: { isLive: () => true }
      });
    }
  }, [isTimerLive, listing, client]);

  const { state, startOfTimer, endOfTimer } = getTimerState({ startOfSale, endOfSale, isLive, currentTime, start });
  const isComingSoon = !startOfSale && !isLive && !endOfSale;
  const isListingLive = isLive || state === states.FINALMINUTE || state === states.LIVE;
  const percentFull = 100 - Math.floor(((currentTime - startOfTimer) / (endOfTimer - startOfTimer)) * 100);
  const color = getColorFromState(state);

  if (isComingSoon || (isListingLive && !showTimer)) {
    return (
      <CountdownTimerContainer>
        <TimerLabel label={ isComingSoon ? 'Coming soon!' : 'Listing live!' } />
        <ProgressBar percentFull={ 0 } />
      </CountdownTimerContainer>
    );
  }

  if (!startOfSale || (isLive && !endOfSale) || isSSR()) {
    return null;
  }
  return (
    <Container>
      <TimerText
        center
        label={ getLabelFromState(state) }
        color={ text[color] }
        time={ getTimeRemaining({ state, startOfTimer, endOfTimer, currentTime }) }
      />
      <ProgressBar percentFull={ percentFull } color={ icon[color] } />
    </Container>
  );
};

const CountdownTimerContainer = styled.div`
  ${flexAlignHorizontalContent('center', 'center')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq(LARGE_MOBILE, 'display: none;')}
`;

const Container = styled.div`
  width: 100%;
  ${flexAlignVerticalContent('center', 'center')}
  ${mq(LARGE_MOBILE, 'display: none;')}
`;

ProgressBarCountdown.defaultProps = {
  listing: {},
  showTimer: false
};

ProgressBarCountdown.propTypes = {
  listing: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isLive: PropTypes.bool
  }),
  showTimer: PropTypes.bool
};

export default ProgressBarCountdown;
