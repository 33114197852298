import styled from 'styled-components';
import { body, text, label, background, spacing } from '@carvana/helios-styles';
import { flexAlignVerticalContent, flexAlignHorizontalContent } from '@src/styles/mixins';

import SignOut from '../icons/SignOut';
import GrayLine from '../GrayLine';
import Avatar from './Avatar';

import '@carvana/helios-styles/fonts.css';

const UserMenu = ({ name, email, isOpen, onClick, onSignout }) => {
  return (
    <DropdownMenu>
      <Avatar letter={ name?.[0] } onClick={ onClick } />
      { isOpen && (
        <Menu>
          <UserInfo>
            <UsernameText>{name}</UsernameText>
            <EmailText>{email}</EmailText>
          </UserInfo>
          <GrayLine margin={ 0 } />
          <SignOutButton onClick={ onSignout }>
            <SignOut />
            Sign Out
          </SignOutButton>
        </Menu>
      ) }
    </DropdownMenu>
  );
};

const DropdownMenu = styled.div`
  position: relative;
`;

const Menu = styled.div`
  ${flexAlignVerticalContent('flex-start', 'flex-start')}
  min-width: 375px;
  background: ${background.default};
  position: absolute;
  border-radius: 8px;
  gap: 1rem;
  top: 40px;
  right: 0;
  padding: 1.5rem;
  box-shadow: 0px 8px 28px rgba(24, 53, 88, 0.04),
    0px 4px 8px rgba(24, 53, 88, 0.07), 0px -2px 8px rgba(24, 53, 88, 0.03);
`;

const SignOutButton = styled.button`
  ${label.md}
  ${flexAlignHorizontalContent('flex-start', 'center')}
  gap: ${spacing.md};
  color: ${text.critical};
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
`;

const UserInfo = styled.div`
  ${flexAlignVerticalContent('flex-start', 'center')}
`;

const UsernameText = styled.p`
  ${label.lg}
  color: ${text.strong};  
`;

const EmailText = styled.p`
  ${body.regular.md}
  color: ${text.strong}; 
`;

export default UserMenu;
