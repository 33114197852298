/* eslint-disable max-len */
import React from 'react';

const TimerSpeed = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13 39.571C16.203 46.328 23.077 51 31.04 51 42.063 51 51 42.046 51 31s-8.937-20-19.96-20C23.077 11 16.203 15.672 13 22.429' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
      <path d='M30.61 32.85h.78c.22 0 .44-.202.464-.405l.95-7.124v-2.84c0-.253-.219-.481-.462-.481h-2.683c-.244 0-.464.228-.464.482v2.89l.951 7.073a.502.502 0 0 0 .464.406zM29 37.922c0 1.141.902 2.079 1.976 2.079C32.098 40 33 39.062 33 37.921c0-1.14-.902-2.104-2.024-2.104-1.074 0-1.976.963-1.976 2.104z' fill='#00AED9' />
      <path d='M26 5h10m-5 0v5.917' stroke='#00619E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /><path d='M7 26h6M7 36h6M3 31h11.003' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default TimerSpeed;
