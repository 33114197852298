import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { typeStyle } from '@carvana/showroom-css-theme';
import { AuctionLogo, ExternalLink, Menu, Close } from '@src/components/icons';
import { TimerText, getTimeRemaining } from '@src/components/CountdownTimer';
import useSaleEndsIn from '@src/hooks/useSaleEndsIn';
import useScrollPosition from '@src/hooks/useScrollPosition';
import {
  flexAlignHorizontalContent,
  flexAlignVerticalContent,
  LARGE_MOBILE,
  DESKTOP,
  mq,
} from '@src/styles/mixins';
import TrackedLink from '../TrackedLink';

const Header = () => {
  const { start: startOfTimer, end: endOfTimer, currentTime } = useSaleEndsIn(); // times in seconds
  const { pathname } = useRouter();
  const scrollPosition = useScrollPosition();
  const isSticky = scrollPosition > 400 - 70; // hero height - header height
  const isLandingPage = pathname === '/';

  return (
    <HeaderContainer $isLandingPage={ isLandingPage } $isSticky={ isSticky }>
      <MobileMenu />
      <Link href='/'>
        <AuctionLogo variant={ isLandingPage ? 'primary' : 'secondary' } />
      </Link>
      <TimerWrapper>
        <TimerText
          center
          label={ "Today's CarvanaAccess Auction ends in:" }
          color='var(--cvna-color-white)'
          labelColor='var(--cvna-color-white)'
          time={ getTimeRemaining({ startOfTimer, endOfTimer, currentTime }) }
        />
      </TimerWrapper>
      <ButtonWrapper>
        <SignInButton
          size='small'
          href='/browse'
          segmentProps={{
            event: 'Carvana Auction Sign In Clicked',
            props: {
              component: 'Header Sign In Button',
            },
          }}
        >
          Sign In To Carvana Auction
        </SignInButton>

        <HelpButton
          href={ process.env.CARVANA_AUCTION_HELP_URL }
          segmentProps={{
            event: 'Help Clicked',
            props: {
              component: 'Header Help Button',
            },
          }}
        >
          Help
          <ExternalLink />
        </HelpButton>
      </ButtonWrapper>
    </HeaderContainer>
  );
};

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  // disable body scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      <MenuButton design='tertiary' onClick={ toggleMenu }>
        <Menu />
      </MenuButton>
      {isOpen && (
        <MobileMenuContainer>
          <CloseButton design='tertiary' onClick={ toggleMenu }>
            <Close />
          </CloseButton>
          <MobileMenuTitle>Menu</MobileMenuTitle>
          <MenuItems>
            <MobileSignInButton
              href='/browse'
              segmentProps={{
                event: 'Carvana Auction Sign In Clicked',
                props: {
                  component: 'Header Sign In Button',
                },
              }}
            >
              Sign In To Carvana Auction
            </MobileSignInButton>
            <MobileHelpButton
              href={ process.env.CARVANA_AUCTION_HELP_URL }
              segmentProps={{
                event: 'Help Clicked',
                props: {
                  component: 'Header Help Button',
                },
              }}
            >
              Help
              <ExternalLink />
            </MobileHelpButton>
          </MenuItems>
        </MobileMenuContainer>
      )}
    </>
  );
};

const HeaderContainer = styled.header`
  ${flexAlignHorizontalContent('space-between', 'center')}
  gap: 18px;
  width: 100%;
  height: var(--header-height);
  background-color: var(--cvna-color-white);
  top: 0;
  padding: 0 1.5rem;
  box-shadow: 0px 2px 3px rgba(24, 53, 88, 0.1);
  z-index: 300;

  > a,
  > div {
    flex-grow: 1;
    flex-basis: 0;
  }

  ${({ $isLandingPage }) => $isLandingPage && css`
    position: absolute;
    background-color: linear-gradient(
      180deg,
      var(--cvna-color-blue-6) 0%,
      rgba(24, 53, 88, 0) 100%
    );
    box-shadow: none;
  `}

  ${({ $isSticky }) => $isSticky && css`
    position: fixed;
    animation: slideDown 0.3s ease-in-out;
    background-color: var(--cvna-color-blue-6);

    @keyframes slideDown {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
  `}
`;

const ButtonWrapper = styled.div`
  ${flexAlignHorizontalContent('flex-end', 'center')}
  gap: 24px;

  ${mq(LARGE_MOBILE, `
    display: none;
  `)}
`;

const HelpButton = styled(TrackedLink)`
  ${typeStyle('label-m')};
  ${flexAlignHorizontalContent('center', 'center')};
  color: var(--cvna-color-white);
  gap: 9px;

  :hover {
    text-decoration: underline;
    color: var(--cvna-color-white);
  }
`;

const TimerWrapper = styled.div`
  ${mq('max-width: 1185px', 'display: none;')}
`;

const SignInButton = styled(TrackedLink)`
  ${typeStyle('label-m')};
  padding: 12px 30px;
  color: var(--cvna-color-white);
  border: 1px solid var(--cvna-color-white);
  border-radius: 3px;

  :hover {
    text-decoration: underline;
    color: var(--cvna-color-white);
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  min-height: fit-content;
  top: 16px;
  right: 16px;
  color: var(--cvna-color-blue-6);
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: var(--cvna-color-white);

  ${mq(DESKTOP, `
    display: none;
  `)}
`;

const MobileMenuContainer = styled.div`
  position: absolute;
  background: var(--cvna-color-white);
  height: 100vh;
  inset: 0;
  padding: 16px;

  ${mq(DESKTOP, `
    display: none;
  `)}
`;

const MobileMenuTitle = styled.div`
  ${typeStyle('header-m')}
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  color: var(--cvna-color-blue-6);
`;

const MenuItems = styled.div`
  ${flexAlignVerticalContent('flex-start', 'center')};
  gap: 16px;
  padding: 32px 0;
`;

const MobileSignInButton = styled(SignInButton)`
  ${flexAlignHorizontalContent('center', 'center')};
  background-color: var(--cvna-color-blue-2);
  width: 100%;

  :hover {
    background-color: var(--cvna-color-blue-3);
    color: var(--cvna-color-white);
  }
`;

const MobileHelpButton = styled(HelpButton)`
  width: 100%;
  padding: 12px 30px;
  border: 1px solid var(--cvna-color-blue-2);
  border-radius: 3px;
  color: var(--cvna-color-blue-2);

  :hover {
    background-color: var(--cvna-color-blue-3);
    border: 1px solid var(--cvna-color-blue-3);
  }
`;

export default Header;
