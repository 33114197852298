import { useMemo, useContext } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { createRegisteredContext, getContext } from 'react-singleton-context';

export const AnalyticsContext = createRegisteredContext('AnalyticsContext', null);

export const AnalyticsProvider = ({ children, writeKey }) => {
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey]
  );

  return (
    <AnalyticsContext.Provider value={ analytics }>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const result = useContext(getContext(AnalyticsContext));
  if (!result) {
    throw new Error('Analytics Context used outside of Provider');
  }
  return result;
};
