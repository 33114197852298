/* eslint-disable max-len */
import React from 'react';

const HandshakeApplied = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'>
    <path stroke='#00619E' d='m27.85 20.04-3.52-1.07a4 4 0 0 0-3 .28l-2.28 1.18a4 4 0 0 1-3.28.18l-3.54-1.37a4 4 0 0 0-4.6 1.28L4.38 24.7a4 4 0 0 0 .6 5.53l3.8 3.14' />
    <rect width='5.61' height='9.96' x='12.2' y='30.68' stroke='#00619E' rx='2.8' transform='rotate(45 12.2 30.68)' />
    <rect width='5.61' height='9.96' x='19.62' y='39.41' stroke='#00619E' rx='2.8' transform='rotate(45 19.62 39.4)' />
    <rect width='5.61' height='11.62' x='16.28' y='34.68' stroke='#00619E' rx='2.8' transform='rotate(45 16.28 34.68)' />
    <path stroke='#00619E' d='m25.36 50.06 1.7 1.7a2.8 2.8 0 0 0 3.96 0 2.8 2.8 0 0 0 0-3.95l-1.98-1.98 4.27 4.28a2.47 2.47 0 0 0 3.5 0 2.47 2.47 0 0 0 0-3.5l-5.14-5.13 5.35 5.35a2.86 2.86 0 0 0 4.04 0 2.86 2.86 0 0 0 0-4.04l-6.17-6.17 6.45 6.45a2.53 2.53 0 0 0 3.64-.08 2.53 2.53 0 0 0-.06-3.49l-12.8-12.93' />
    <rect width='5.61' height='8.37' x='23.61' y='43.51' stroke='#00619E' rx='2.8' transform='rotate(45 23.61 43.51)' />
    <path fill='#00619E' d='M28.7 20.92a1 1 0 1 0-1.13-1.65l1.13 1.65Zm17.43 14.87-.74-.68a1 1 0 0 0-.06.07l.8.6Zm6.16-12.17a1 1 0 0 0-1.47 1.35l1.47-1.35Zm-19.83 3.25-.7.7.7-.7Zm12.97 9.84.8.6-.8-.6Zm-3.02.2-.7.7.7-.7Zm9.2-12.55-.73.68.74-.68Zm-23.38 3.1-.42-.91.42.9Zm-2.48-4.52 2.95-2.02-1.13-1.65-2.95 2.02 1.13 1.65Zm25.14 6.16-5.5 6.01 1.47 1.35 5.5-6.02-1.47-1.35Zm-5.56 6.08-.7.92 1.6 1.2.7-.91-1.6-1.2Zm-16.67-6.82 2.07-.97-.85-1.81-2.07.97.85 1.81Zm3.1-.79 9.94 10.04 1.42-1.4-9.95-10.05-1.42 1.41Zm19.06-2.6.06.07 1.47-1.35-.06-.07-1.47 1.35ZM30.73 27.4a.9.9 0 0 1 1.02.18l1.42-1.4a2.9 2.9 0 0 0-3.29-.6l.85 1.82Zm13.9 8.71a1 1 0 0 1-1.5.1l-1.43 1.41a3 3 0 0 0 4.53-.3l-1.6-1.2Zm7.73-5.66a5 5 0 0 0 0-6.75l-1.48 1.35a3 3 0 0 1 0 4.05l1.48 1.35Zm-27.4-2.2a4.09 4.09 0 0 0 3.7.12l-.85-1.81a2.08 2.08 0 0 1-1.89-.06l-.96 1.75Zm-.34-6.95a4.09 4.09 0 0 0 .34 6.95l.96-1.75a2.09 2.09 0 0 1-.17-3.55l-1.13-1.65Z' />
    <path stroke='#00AED9' d='M37.33 14 41 17.67l8.54-10.6A10.95 10.95 0 0 1 52 14a11 11 0 1 1-8.25-10.65' />
  </svg>
);

export default HandshakeApplied;
