import { from, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import logLink from './logging';

const createCompositeLink = ({ basePath = '' } = {}) => {
  const httpLink = new HttpLink({
    uri: () => {
      // MSW running in node (unit tests) requires an absolute URL
      return `${
        process.env.NODE_ENV === 'test' ? 'http://unittest.com' : ''
      }${basePath}/api/auction_graph`;
    },
  });

  const additiveLink = from([
    onError(({ networkError, operation }) => {
      const definition = getMainDefinition(operation.query);
      if (networkError) {
        datadogRum.addError(networkError, {
          request: {
            operationType: definition.operation,
            operationName: definition.name?.value,
          },
        });
      }
      if (networkError?.statusCode === 401) {
        window.location.href = process.env.NEXT_PUBLIC_AUTH0_LOGOUT;
      }
    }),
    logLink,
    httpLink,
  ]);

  return additiveLink;
};

export default createCompositeLink;
