import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import isSSR from '../utils/isSSR';
import { getGtag } from '../utils/gtag';
import { useAnalytics } from '../contexts/AnalyticsContext';

const track = (analytics, {
  method,
  props,
  additionalProps,
  event,
  segmentOpts,
  element,
  cb
}) => {
  // We wrap this in a ready to make sure FS is available.
  analytics.ready?.(async () => {
    const totalProps = {
      ...props,
      ...additionalProps,
      fullstory_url: window.FS?.getCurrentSessionURL?.(true) || null,
      gtag_client_id: await getGtag('client_id'),
      gtag_session_id: await getGtag('session_id')
    };

    if (method === 'trackLink' && element) {
      try {
        analytics.trackLink(element, event, totalProps, segmentOpts);
      } catch (error) {
        // Segment throws an error if the trackLink call takes longer than 300ms
        // This doesn't affect the user experience, so we just log the error to prevent monitor noise
        // eslint-disable-next-line no-console
        console.warn('Segment trackLink error', error);
      }
    } else {
      analytics.track(event, totalProps, segmentOpts, cb);
    }
  });
};

const useSegmentTrack = (method) => {
  const analytics = useAnalytics();
  const client = useApolloClient();

  /**
   * The actual hook so we can execute it outside of functional components
   *
   * @param {string} event - The name of the segment event
   * @param {string} opts - options including segment props and track event options
   * @param {Object} opts.query - graphql query that triggered the track event
   * @param {Object} opts.segmentOpts - options for the segment track function
   * @param {Object} opts.props - any non-shared props to pass to the segment event
   * @param {Object} opts.variables - an object containing the query variables
   * @param {function} opts.transformer - a function to transform the cache data to segment props
   * @param {function} cb - callback to pass to segment track function
   */
  return useCallback((event, {
    query, variables, props: additionalProps, transformer, segmentOpts, element,
  } = {}, cb = undefined) => {
    if (!event || typeof event !== 'string' || isSSR()) {
      return;
    }

    let props = {};

    if (query && variables.id) {
      let resData;
      const subscription = client.watchQuery({ query, variables, errorPolicy: 'all', fetchPolicy: 'cache-only' }).subscribe({
        next: ({ data }) => {
          resData = data;
          props = (transformer && transformer(data, variables)) || {};
          track(analytics, {
            method, props, additionalProps, event, segmentOpts, element, cb
          });
          if (resData) {
            subscription.unsubscribe();
          }
        }
      });
    } else {
      track(analytics, {
        method, props, additionalProps, event, segmentOpts, element, cb
      });
    }
  }, [analytics, client, method]);
};

useSegmentTrack.defaultProps = {
  method: 'track'
};

useSegmentTrack.propTypes = {
  method: PropTypes.oneOf(['track', 'trackLink'])
};

export default useSegmentTrack;
