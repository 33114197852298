import { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';
import { useUser } from '@auth0/nextjs-auth0/client';
import {
  reference,
  spacing,
  text,
  label as labelFont,
  heading,
  body,
  background,
  subBrand,
} from '@carvana/helios-styles';
import { useModal } from '@carvana/helios-layout';
import { Button } from '@carvana/helios-navigation';
import { flexAlignHorizontalContent, flexAlignVerticalContent } from '@src/styles/mixins';
import { useAnalytics } from '@src/contexts/AnalyticsContext';

import { AuctionLogo } from '../icons';
import UserMenu from './UserMenu';

const AuthHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { Modal, openModal, closeModal } = useModal();
  const { user } = useUser();
  const { pathname } = useRouter();
  const analytics = useAnalytics();

  const getButtonClassName = segment => pathname?.includes(segment) ? 'nav--active' : 'nav--inactive';

  const navLinks = [
    {
      label: 'Buy',
      className: getButtonClassName('browse'),
      href: '/browse',
    },
  ];

  const toggleUserMenu = () => setIsMenuOpen(!isMenuOpen);

  const onSignout = () => {
    openModal();
  };

  const handleSignout = () => {
    analytics.reset();
    localStorage.removeItem(`user_${user.sub}`);
    window.location.href = process.env.NEXT_PUBLIC_AUTH0_LOGOUT;
  };

  return (
    <>
      <HeaderContainer onClick={ () => isMenuOpen && toggleUserMenu() }>
        <Left>
          <Link href='/browse'>
            <AuctionLogo variant='primary' />
          </Link>
          <Nav>
            {navLinks.map(({ label, as, ...rest }) => (
              <NavLink key={ label } forwardedAs={ as } { ...rest }>
                { label }
              </NavLink>
            ))}
          </Nav>
        </Left>
        <Icons>
          <UserMenu
            name={ user.name }
            email={ user.email }
            isOpen={ isMenuOpen }
            onClick={ toggleUserMenu }
            onSignout={ onSignout }
          />
        </Icons>
      </HeaderContainer>
      { isMenuOpen && <Backdrop onClick={ toggleUserMenu } /> }
      <SignOutConfirmationModal
        Modal={ Modal }
        closeModal={ closeModal }
        handleSignout={ handleSignout }
      />
    </>
  );
};

const SignOutConfirmationModal = ({ Modal, closeModal, handleSignout }) => {
  return (
    <Modal data-qa='signout-modal'>
      <Body>
        <Header>Sign out</Header>
        <p>Are you sure you want to sign out?</p>
      </Body>
      <Footer>
        <CancelBtn variant='primaryInverted' utility onClick={ closeModal } data-qa='signout-cancell-btn'>
          <CancelBtnText>Cancel</CancelBtnText>
        </CancelBtn>
        <SignOutBtn variant='primary' utility onClick={ handleSignout } data-qa='signout-confirm-btn'>
          <SignOutBtnText>Sign out</SignOutBtnText>
        </SignOutBtn>
      </Footer>
    </Modal>
  );
};

const HeaderContainer = styled.header`
  ${flexAlignHorizontalContent('normal', 'center')}
  gap: ${spacing.xl};
  height: var(--header-height);
  background-color: ${subBrand.auction2};
  border-bottom: none;
  position: sticky;
  top: 0;
  padding: ${spacing.xl} ${spacing['2Xl']};
  z-index: 300;
`;

const Left = styled.div`
  ${flexAlignHorizontalContent('center', 'center')}
  gap: ${spacing['3Xl']};
  & > a {
    line-height: 0;
  }
`;

const Icons = styled.div`
  ${flexAlignHorizontalContent('flex-end', 'center')}
  gap: ${spacing['2Xl']};
  flex: 1 0 0;
  & a {
    line-height: 0;
  }
`;

const Nav = styled.nav`
  display: flex;
`;

const NavLink = styled(Link)`
  ${labelFont.sm}
  padding: ${spacing.lg} ${spacing['2Xl']};
  &.nav--inactive {
    color: ${text.disabledSubtle};
  }
  &.nav--active {
    color: ${text.inverse};
  }
  :hover {
    color: ${text.inverse};
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${reference.blue[700]};
  opacity: 0.6;
  z-index: 200;
`;

const Header = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
  ${heading.lg}
  color: ${text.strong};
  margin-bottom: 20px;
`;

const Body = styled.span`
  ${flexAlignVerticalContent('center', 'center')}
  ${body.regular.md}
  color: ${text.weak};
  margin-bottom: 25px;
`;

const Footer = styled.div`
  ${flexAlignHorizontalContent('flex-end', 'center')}
  gap: 10px;
`;

const CancelBtn = styled(Button)`
  ${background.weakTransparent}
`;

const CancelBtnText = styled.span`
  ${labelFont.sm}
  color: ${text.primary};
`;

const SignOutBtn = styled(Button)`
  ${background.primary}
`;

const SignOutBtnText = styled.span`
  ${labelFont.sm}
  color: ${text.inverse};
`;

export default AuthHeader;
