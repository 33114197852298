import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { showChat } from '@src/utils/zendesk';

import MaxContentWrapper from '../MaxContentWrapper';
import GrayLine from '../GrayLine';
import TrackedLink from '../TrackedLink';

const Footer = () => {
  return (
    <MaxContentWrapper>
      <GrayLine width='80%' />
      <InfoFooter>
        <MoreQuestions>
          <FormattedMessage id='MORE_QUESTIONS' />
        </MoreQuestions>
        <LinksContainer>
          <FaqLink
            href={ process.env.CARVANA_AUCTION_HELP_URL }
            segmentProps={{
              event: 'Help Clicked',
              props: { component: 'Footer Learn More Button' }
            }}
          >
            <FormattedMessage id='LEARN_MORE' />
          </FaqLink>
          <span>
            <FormattedMessage id='OR' />
          </span>
          <Contact role='button' tabIndex={ -2 } onClick={ showChat }>
            <FormattedMessage id='CONTACT_US' />
          </Contact>
        </LinksContainer>
      </InfoFooter>
    </MaxContentWrapper>
  );
};

const MoreQuestions = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.color.primary.v600};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const InfoFooter = styled.div`
  text-align: center;
  padding: 40px 0 65px;
  & span {
    float: left;
    margin: 0 5px;
    font-size: 16px;
    line-height: 1;
  }
`;

const LinksContainer = styled.div`
  display: inline-block;
`;

const FaqLink = styled(TrackedLink)`
  float: left;
  color: ${({ theme }) => theme.color.primary.v200};
  font-size: 16px;
  line-height: 1;
  &:hover {
    text-decoration: underline;
  }
`;

const Contact = styled.a`
  float: left;
  color: ${({ theme }) => theme.color.primary.v200};
  font-size: 16px;
  line-height: 1;
  outline: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default Footer;
