import { useState, useEffect, useRef } from 'react';

const MINUTES = 60 * 1000;

/**
 * state is on a timeout and will trigger a
 * component refresh every interval in milliseconds
 *
 * @method useIntervalRefresh
 * @param {number} interval - time in milliseconds
 * @param {number} addMinutes - time in minutes to offset the timer (optional)
 * @return a utc epoch timestamp every interval in milliseconds
 */
export default function useIntervalRefresh(interval = 1000, addMinutes = 0) {
  const timer = useRef(null);
  const additionalTime = addMinutes * MINUTES;
  if (interval < 1000) {
    throw new Error('interval must be greater than 1000 milliseconds');
  }

  const [curTime, setTime] = useState(0);
  const clearInterval = () => {
    clearTimeout(timer.current);
  };

  const restartTimer = () => {
    setTime(Date.now() + additionalTime);
  };

  useEffect(() => {
    setTime(Date.now() + additionalTime);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timerId = setTimeout(() => setTime(Date.now() + additionalTime), interval);
    timer.current = timerId;

    return () => clearTimeout(timer.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curTime]);

  return { currentTime: curTime, clearInterval, restartTimer };
}
