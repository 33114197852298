import PropTypes from 'prop-types';
import styled from 'styled-components';

const CircleProgress = ({
  diameter, padding, progress, trackWidth, trackColor, indicatorWidth, indicatorColor, indicatorCap
}) => {
  const radius = diameter / 2;
  const center = radius;
  const innerRadius = radius - padding;
  const circumference = 2 * Math.PI * innerRadius;
  const offset = circumference * ((100 - progress) / 100);

  return (
    <svg width={ diameter } height={ diameter } viewBox={ `0 0 ${diameter} ${diameter}` }>
      <Track
        cx={ center }
        cy={ center }
        r={ innerRadius }
        stroke={ trackColor }
        strokeWidth={ trackWidth }
      />
      <Indicator
        cx={ center }
        cy={ center }
        r={ innerRadius }
        stroke={ indicatorColor }
        strokeWidth={ indicatorWidth }
        strokeDasharray={ circumference }
        strokeDashoffset={ offset }
        strokeLinecap={ indicatorCap }
      />
    </svg>
  );
};

const Track = styled.circle`
  fill: transparent;
`;

const Indicator = styled.circle`
  fill: transparent;
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: center;
`;

CircleProgress.defaultProps = {
  diameter: 200,
  padding: 10,
  progress: 10,
  trackWidth: 10,
  trackColor: 'var(--cvna-color-grey-2)',
  indicatorWidth: 10,
  indicatorColor: 'var(--cvna-color-blue-2)',
  indicatorCap: 'round'
};

CircleProgress.propTypes = {
  diameter: PropTypes.number,
  padding: PropTypes.number,
  progress: PropTypes.number,
  trackWidth: PropTypes.number,
  trackColor: PropTypes.string,
  indicatorWidth: PropTypes.number,
  indicatorColor: PropTypes.string,
  indicatorCap: PropTypes.string
};

export default CircleProgress;
