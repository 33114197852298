/* eslint-disable max-len */
import PropTypes from 'prop-types';

const Copy = ({ size }) => (
  <svg width={ size } height={ size } viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='8' y='3' width='12' height='14' rx='2' stroke='currentColor' strokeWidth='2' />
    <path d='M12 8h4m-4 4h2' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 7H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1.506' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
  </svg>
);

Copy.defaultProps = {
  size: 24
};

Copy.propTypes = {
  size: PropTypes.number
};

export default Copy;
