const Adjustment = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
      <path
        stroke='currentColor'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M11 11a2 2 0 1 0 4 0 2 2 0 0 0-4 0zM1 5a2 2 0 1 0 4 0 2 2 0 0 0-4 0z'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M1 11h10H1zm4-6h10H5z'
      />
    </svg>
  );
};

export default Adjustment;
