/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ size }) => (
  <svg width={ size } height={ size } viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.5 1.68874L10.1477 5.14186L10.3177 5.4981L10.7091 5.54969L14.5024 6.04972L11.7274 8.68388L11.4412 8.95564L11.513 9.34376L12.2097 13.1059L8.84692 11.2808L8.5 11.0925L8.15308 11.2808L4.79034 13.1059L5.48697 9.34376L5.55884 8.95564L5.27257 8.68388L2.49764 6.04972L6.29093 5.54969L6.68227 5.4981L6.85226 5.14186L8.5 1.68874Z'
      stroke='currentColor'
      strokeWidth='1.45455'
      strokeLinecap='round'
    />
  </svg>
);

Star.defaultProps = {
  size: 16
};

Star.propTypes = {
  size: PropTypes.number
};

export default Star;
