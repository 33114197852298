import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import { FormattedMessage } from 'react-intl';
import { flexAlignHorizontalContent, flexAlignVerticalContent } from '@src/styles/mixins';
import useSaleEndsIn from '@hooks/useSaleEndsIn';

import { getTimeRemaining } from '../utils';
import CircleProgressBar from './CircleProgressBar';

const CircularCountdown = ({ diameter, padding }) => {
  const { start, end, currentTime } = useSaleEndsIn(); // times in seconds
  const time = getTimeRemaining({ startOfTimer: start, endOfTimer: end, currentTime });
  const progress = 100 - Math.floor(((currentTime - start) / (end - start)) * 100); // progress in percent

  return (
    <CountdownTimerWrapper>
      <CountdownTimer>
        <CircleProgressBar
          diameter={ diameter }
          padding={ padding }
          progress={ progress }
        />
        <Timer>
          <Time>
            <TimeText>
              {time.hours}
            </TimeText>
            <UnitText>
              <FormattedMessage id='HOURS' />
            </UnitText>
          </Time>
          <TimeText>:</TimeText>
          <Time>
            <TimeText>
              {time.minutes}
            </TimeText>
            <UnitText>
              <FormattedMessage id='MINUTES' />
            </UnitText>
          </Time>
          <TimeText>:</TimeText>
          <Time>
            <TimeText>
              {time.seconds}
            </TimeText>
            <UnitText>
              <FormattedMessage id='SECONDS' />
            </UnitText>
          </Time>
        </Timer>
      </CountdownTimer>
      <Point />
    </CountdownTimerWrapper>
  );
};

const CountdownTimerWrapper = styled.div`
  ${flexAlignHorizontalContent('center', 'center')}
  position: relative;
  margin-bottom: 32px;
`;

const CountdownTimer = styled.div`
  ${flexAlignHorizontalContent('center', 'center')}
  background: var(--cvna-color-white);
  filter: drop-shadow(0 9px 6px rgba(24, 53, 88, 0.1));
  border-radius: 50%;
  margin: 0 auto;
`;

const Point = styled.div`
  position: absolute;
  bottom: -11px;
  width: 0; 
  height: 0; 
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid var(--cvna-color-white);
`;

const Timer = styled.div`
  ${flexAlignHorizontalContent('center', 'flex-start')}
  position: absolute;
  width: 100%;
  gap: 2px;
  top: 45%;
`;

const Time = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
  min-width: 36px;
`;

const TimeText = styled.p`
  ${typeStyle('brand-s')}
  color: var(--cvna-color-blue-6);
  line-height: 18px;
`;

const UnitText = styled.p`
  ${typeStyle('label-xs')}
  text-transform: capitalize;
  font-weight: 390;
  color: var(--cvna-color-grey-3);
  line-height: 18px;
`;

CircularCountdown.defaultProps = {
  diameter: 200,
  padding: 16
};

CircularCountdown.propTypes = {
  diameter: PropTypes.number,
  padding: PropTypes.number
};

export default CircularCountdown;
