/* eslint-disable max-len */

const ExternalLink = () => (
  <svg
    width='16'
    height='16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 6.72a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1H9.353a1 1 0 1 0 0 2h3.234L7.222 7.364a1 1 0 0 0 1.415 1.414L14 3.414V6.72ZM2 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-2.287a1 1 0 0 0-2 0V14H2V3h2.346a1 1 0 0 0 0-2H2Z'
      fill='currentColor'
    />
  </svg>
);

export default ExternalLink;
