import 'react-singleton-context';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import NextApp from 'next/app';
import Head from 'next/head';

import TagManager from 'react-gtm-module';

// eslint-disable-next-line import/extensions
import AppProviders from '@components/AppProviders';
import Layout from '@components/Layout';
import useDataDog from '@hooks/useDataDog';
import * as locales from '../content/locale';
import { initMocks } from '../mocks';

import '@carvana/showroom-css-theme/Theme.css';
import '@carvana/showroom-css-theme/Reset.css';
import '@carvana/showroom-css-theme/Font.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/globalStyles.css';

if (process.env.DEPLOY_ENV !== 'prod' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  initMocks();
}

const tagManagerConfig = {
  gtmId: process.env.CVNA_APP_GTM_ID
};

const App = ({ Component, pageProps, ld }) => {
  const { user } = pageProps;
  useDataDog(user);

  useEffect(() => {
    TagManager.initialize(tagManagerConfig);
  }, []);

  return (
    <>
      <Head>
        <title>Carvana Auction</title>
      </Head>
      <AppProviders auth0User={ user } ld={ ld } locales={ locales }>
        <Layout>
          <Component { ...pageProps } />
        </Layout>
      </AppProviders>
    </>
  );
};

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  const { ld = {} } = appContext?.ctx?.req || {};

  return { ...appProps, ld };
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({
    session: PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string
      }),
      expires: PropTypes.string
    })
  }).isRequired,
  ld: PropTypes.shape({
    features: PropTypes.shape({
      $flagsState: PropTypes.shape({}),
    }),
    context: PropTypes.shape({}),
  }).isRequired
};

export default App;
