/**
 * Time utils that deal with utc dates
 */
import { DateTime } from 'luxon';

export const toSeconds = ms => Math.trunc(ms / 1000);

export function easternTime(time) {
  return DateTime.fromMillis(time).setZone('America/New_York');
}

export const defaultSaleEndTime = { hour: 16, minute: 0 };

export const saleStartEasternTime = (time, saleEnd) => {
  const date = easternTime(time ?? Date.now());
  saleEnd = saleEnd || defaultSaleEndTime;

  return date.set({
    hour: saleEnd.hour,
    minute: saleEnd.minute,
    seconds: 0
  });
};

export function convertSecondsToHoursMinutes(time) {
  let remainder = time;
  const hours = Math.trunc(remainder / 3600);
  remainder -= (hours * 3600);

  const minutes = Math.trunc(remainder / 60);
  remainder -= (minutes * 60);

  const seconds = remainder;

  const padHours = padTime(hours);
  const padMinutes = padTime(minutes);
  const padSeconds = padTime(seconds);

  return {
    hours: padHours,
    minutes: padMinutes,
    seconds: padSeconds,
    time: `${padHours}:${padMinutes}:${padSeconds}`
  };
}

const padTime = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
};
