import { createContext, useContext, useState, useMemo } from 'react';

export const WebSocketContext = createContext({
  socketState: {},
  setSocketState: () => null
});

export const WebSocketProvider = ({ children }) => {
  const [socketState, setSocketState] = useState('');
  const value = useMemo(
    () => ({
      socketState,
      setSocketState,
    }),
    [socketState, setSocketState]
  );
  return (
    <WebSocketContext.Provider value={ value }>
      { children }
    </WebSocketContext.Provider>
  );
};

export const useWebSocketState = () => useContext(WebSocketContext);
