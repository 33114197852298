import PropTypes from 'prop-types';

const LockOutline = ({ color }) => (
  <svg role='img' aria-hidden='true' width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='6.00195' width='9' height='7' stroke={ color } />
    <path d='M7.5 3.5V6H2.5V3.5C2.5 2.11929 3.61929 1 5 1C6.38071 1 7.5 2.11929 7.5 3.5Z' stroke={ color } />
    <circle cx='5' cy='8.41992' r='1' fill={ color } />
    <path d='M5 8.41992V11.4199' stroke={ color } strokeLinecap='round' />
  </svg>
);

LockOutline.defaultProps = {
  color: '#666666'
};

LockOutline.propTypes = {
  color: PropTypes.string
};

export default LockOutline;
