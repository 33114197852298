/* eslint-disable max-len */
import React from 'react';

const VehicleSearch = () => (
  <svg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M36.8751 36.8749L49.9993 49.9993' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    <circle cx='21.8763' cy='21.8753' r='20.8753' stroke='#00619E' strokeWidth='2' />
    <path d='M6.5 18H22.867C26.7923 18 29.9999 21.2175 29.9999 25.155V27.5V32H25.5138H8' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' />
    <path d='M12 8H17.7357C22.2857 8 26 11.2 26 15.12V18H5' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' />
    <path d='M32 16.9987C32 17.773 31.5529 18.4776 30.8524 18.8072L28.8733 19.7386C27.1 20.5616 25 19.3395 25 17.494C25 16.1223 26.19 15 27.6833 15H30.0013C31.1051 15 32 15.8949 32 16.9987Z' fill='#00AED9' />
    <path d='M21 33C21 35.2 22.8 37 25 37H27C29.2 37 31 35.2 31 33H21Z' fill='#00AED9' />
    <circle cx='22' cy='25' r='2' fill='#00AED9' />
    <path d='M17 25L6 25' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' />
  </svg>
);

export default VehicleSearch;
