import * as React from 'react';
import PropTypes from 'prop-types';
import ShowroomToastProvider from '@carvana/showroom-toast';
import { createRegisteredContext, getContext } from 'react-singleton-context';

import '@carvana/showroom-toast/index.css';

const ToastContext = createRegisteredContext('ToastContext', undefined);

export function ToastProvider({ children }) {
  return (
    <ShowroomToastProvider
      render={ ({ showToast }) => {
        return (
          <ToastContext.Provider value={ showToast }>
            {children}
          </ToastContext.Provider>
        );
      } }
    />
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export function useToast() {
  const contextValue = React.useContext(getContext(ToastContext));
  if (contextValue === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return contextValue;
}
