import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PrimaryButton from '@carvana/showroom-forms/Button/Primary';
import SecondaryButton from '@carvana/showroom-forms/Button/Secondary';
import TertiaryButton from '@carvana/showroom-forms/Button/Tertiary';
import { focusStyle, typeStyle } from '@carvana/showroom-css-theme';
import useSegmentTrack from '../hooks/useSegmentTrack';
import TrackedLink from './TrackedLink';

const designs = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton
};

const Link = ({ children, ...props }) => <a { ...props }>{children}</a>;

const LinkButton = ({ children, ...props }) => {
  const {
    className, disabled, href, target, segmentProps, query, variables, transformer
  } = props;
  const StyledLink = linkDesigns[props.design] || linkDesigns.primary;
  const extraProps = {};
  let LinkComponent = Link;

  if (!disabled) {
    extraProps.href = href;
  }
  if (segmentProps) {
    extraProps.segmentProps = segmentProps;
    LinkComponent = TrackedLink;
  }
  if (query && variables.id) {
    extraProps.query = query;
    extraProps.variables = variables;
    extraProps.transformer = transformer;
  }
  return (
    <StyledLink as={ LinkComponent } className={ className } target={ target } { ...extraProps }>
      { children }
    </StyledLink>
  );
};

export default function Button(props) {
  const { design, onClick = () => {}, href, children, segmentProps } = props;
  const newProps = { ...props };
  const ButtonComp = (href && href.length) ? LinkButton : (designs[design] || designs.primary);
  const doSegmentTrack = useSegmentTrack();

  if (!href || !href.length) {
    newProps.onClick = (evt) => {
      if (segmentProps) {
        doSegmentTrack(segmentProps.event || `${children} Clicked`, { props: segmentProps.props });
      }
      onClick(evt);
    };
  }

  return (
    <ButtonComp { ...newProps }>{children}</ButtonComp>
  );
}

const defaultButtonStyles = css`
  ${typeStyle('label-m')}
  display: flex;
  gap: 9px;
  box-sizing: border-box;
  background: transparent;
  position: relative;
  margin: 0;
  padding: 0 20px;
  color: inherit;
  min-height: 56px;
  border-radius: var(--cvna-border-radius);
  min-width: 250px;
  font-weight: 700;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  appearance: none;
  font-family: var(--cvna-font-family);
  text-transform: uppercase;
  font-feature-settings: 'cpsp' on, 'liga' off;

  &.medium-size {
    min-height: 48px;
  }

  &.small-size {
    min-height: 40px;
  }

  &:focus {
    outline: none;
    ${focusStyle('light')}
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    ${focusStyle('light')}
  }

  &:disabled {
    background: var(--cvna-color-grey-2);
    color: var(--cvna-color-grey-3);
    cursor: not-allowed;
    border-color: transparent;
  }
`;

const PrimaryLink = styled.a`
  ${defaultButtonStyles}
  background: var(--cvna-color-blue-2);
  color: var(--cvna-color-white);

  &:hover:not(:disabled) {
    background: var(--cvna-color-blue-3);
    color: var(--cvna-color-white);
  }

  &.dark-mode {
    background: var(--cvna-color-white);
    color: var(--cvna-color-blue-2);

    &:hover:not(:disabled) {
      background: var(--cvna-color-white);
      color: var(--cvna-color-blue-3);
    }

    &:focus {
      ${focusStyle('dark')};
    }

    &:focus-visible {
      ${focusStyle('dark')};
    }
  }
`;

const SecondaryLink = styled.a`
  ${defaultButtonStyles}
  background: transparent;
  border-color: var(--cvna-color-blue-2);
  color: var(--cvna-color-blue-2);

  &:hover:not(:disabled) {
    background: var(--cvna-color-blue-3);
    border-color: var(--cvna-color-blue-3);
    color: var(--cvna-color-white);
  }

  &.dark-mode {
    border-color: var(--cvna-color-white);
    color: var(--cvna-color-white);

    &:hover {
      background: var(--cvna-color-white);
      border-color: var(--cvna-color-white);
      color: var(--cvna-color-blue-3);
    }

    &:focus {
      ${focusStyle('dark')}
    }

    &:focus-visible {
      ${focusStyle('dark')}
    }
  }

  &:disabled {
    background: transparent;
    border-color: var(--cvna-color-grey-3);
  }
`;

const TertiaryLink = styled.a`
  ${defaultButtonStyles}
  background: transparent;
  color: var(--cvna-color-blue-2);
  min-height: 44px;
  min-width: 0;
  padding: 0 16px 0 16px;
  margin: 0 -16px 0 -16px;

  &:hover:not(:disabled) {
    color: var(--cvna-color-blue-3);
  }

  &.dark-mode {
    color: var(--cvna-color-white);

    &:hover:not(:disabled) {
      color: var(--cvna-color-blue-3);
    }

    &:focus {
      ${focusStyle('dark')};
    }

    &:focus-visible {
      ${focusStyle('dark')};
    }
  }

  &:disabled {
    background: transparent;
    border-color: transparent;
  }
`;

const linkDesigns = {
  primary: PrimaryLink,
  secondary: SecondaryLink,
  tertiary: TertiaryLink
};

const defaultProps = {
  disabled: false
};

Link.defaultProps = {
  ...defaultProps
};

LinkButton.defaultProps = {
  ...defaultProps
};

Button.defaultProps = {
  ...defaultProps,
  design: 'primary'
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node
};

LinkButton.propTypes = {
  ...Link.propTypes,
  segmentProps: PropTypes.shape({
    event: PropTypes.string,
    props: PropTypes.shape({})
  }),
  disabled: PropTypes.bool,
};

Button.propTypes = {
  ...LinkButton.propTypes,
  design: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'icon']),
  mode: PropTypes.oneOf(['light', 'dark', 'special']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  onClick: PropTypes.func
};
