/* eslint-disable max-len */
import React from 'react';

const EmojiFrown = () => (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='28' cy='28' r='24.5' stroke='#106BC7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 38.5C21 38.5 22.1667 35 28 35C33.8333 35 35 38.5 35 38.5' stroke='#00AED9' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <circle cx='21' cy='21' r='3.5' fill='#00AED9' />
    <circle cx='35' cy='21' r='3.5' fill='#00AED9' />
  </svg>

);

export default EmojiFrown;
