/* eslint-disable max-len */
import PropTypes from 'prop-types';

const TooltipOutline = ({ color }) => {
  return (
    <svg role='img' aria-hidden='true' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' stroke={ color } strokeWidth='2' />
      <path d='M11.1631 13.1701H12.3915C12.5887 13.1701 12.6948 13.0351 12.6948 12.8551V12.3601C12.6948 11.4601 15 10.9351 15 9.1201C15 7.6501 13.6503 6.6001 12.0579 6.6001C10.1167 6.6001 9.0854 7.9201 9.0854 7.9201C8.97924 8.0251 8.96408 8.2051 9.0854 8.3101L9.79819 9.0001C9.90435 9.0901 10.0105 9.0901 10.1318 9.0151C10.4351 8.8201 10.8446 8.2351 11.7697 8.2351C12.528 8.2351 12.983 8.7001 12.983 9.2401C12.983 10.2901 10.8749 10.8301 10.8749 12.2551V12.8851C10.8749 13.0501 11.0114 13.1701 11.1631 13.1701ZM10.5261 16.1701C10.5261 16.8451 11.0873 17.4001 11.7697 17.4001C12.4522 17.4001 13.0285 16.8451 13.0285 16.1701C13.0285 15.4951 12.4522 14.9251 11.7697 14.9251C11.0873 14.9251 10.5261 15.4951 10.5261 16.1701Z' fill={ color } />
    </svg>
  );
};

TooltipOutline.defaultProps = {
  color: '#666666'
};

TooltipOutline.propTypes = {
  color: PropTypes.string
};

export default TooltipOutline;
