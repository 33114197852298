/* eslint-disable max-len */
export const UsageGood = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='37' height='27' fill='none' viewBox='0 0 37 27'>
    <path fill='currentColor' d='m10.53 8.88 2.83-1.63v3.26l-2.83-1.63Z' />
    <path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.5' d='M25.12 19.24h3.72c.29 0 .52-.23.52-.52v-1.9c0-.8-.52-1.52-1.29-1.78l-4.2-1.4a.94.94 0 0 1-.3-.16L18.3 9.1a.94.94 0 0 0-.6-.22h-4.34' />
    <circle cx='22.3' cy='19.71' r='2.54' stroke='currentColor' strokeWidth='1.5' />
    <path fill='currentColor' d='m18.06 19.24-2.82 1.63V17.6l2.82 1.63ZM9.5 11.42a.75.75 0 1 0-.75-1.3l.74 1.3Zm-3.82 1.26-.15-.74.15.74Zm.28-.1.37.64-.37-.65Zm-4.1 5.71v-3.16H.37v3.16h1.5Zm.92-4.27 3.04-.6-.3-1.48-3.03.61.29 1.47Zm3.15 4.47H2.06v1.5h3.87v-1.5Zm9.3 0H11v1.5h4.23v-1.5Zm-8.9-5.27 3.16-1.8-.74-1.3-3.16 1.8.74 1.3Zm-.5.2c.17-.04.34-.1.5-.2l-.74-1.3a.19.19 0 0 1-.06.02l.3 1.47Zm-3.96 1.71c0-.54.38-1 .9-1.1l-.28-1.48a2.63 2.63 0 0 0-2.12 2.58h1.5ZM.37 18.3c0 .94.76 1.7 1.69 1.7v-1.5a.2.2 0 0 1-.2-.2H.37Z' />
    <circle cx='8.18' cy='19.71' r='2.54' stroke='currentColor' strokeWidth='1.5' />
    <g clipPath='url(#usage-check)'>
      <path fill='#2F9E44' fillRule='evenodd' d='M28.5 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.77-9.36a1 1 0 0 0-1.54-1.28L27.26 9.5l-1.03-1.1a1 1 0 0 0-1.46 1.38L25.94 11l.45.48.13.14.04.04v.01h.01l.73-.68-.73.69a1 1 0 0 0 1.5-.05l4.2-5Z' clipRule='evenodd' />
    </g>
    <defs>
      <clipPath id='usage-check'>
        <path fill='#fff' d='M0 0h16v16H0z' transform='translate(20.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const UsageBad = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='37' height='27' fill='none' viewBox='0 0 37 27'>
    <path fill='currentColor' d='m10.53 8.88 2.83-1.63v3.26l-2.83-1.63Z' />
    <path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.5' d='M25.12 19.24h3.72c.29 0 .52-.23.52-.52v-1.9c0-.8-.52-1.52-1.29-1.78l-4.2-1.4a.94.94 0 0 1-.3-.16L18.3 9.1a.94.94 0 0 0-.6-.22h-4.34' />
    <circle cx='22.3' cy='19.71' r='2.54' stroke='currentColor' strokeWidth='1.5' />
    <path fill='currentColor' d='m18.06 19.24-2.82 1.63V17.6l2.82 1.63ZM9.5 11.42a.75.75 0 1 0-.75-1.3l.74 1.3Zm-3.82 1.26-.15-.74.15.74Zm.28-.1.37.64-.37-.65Zm-4.1 5.71v-3.16H.37v3.16h1.5Zm.92-4.27 3.04-.6-.3-1.48-3.03.61.29 1.47Zm3.15 4.47H2.06v1.5h3.87v-1.5Zm9.3 0H11v1.5h4.23v-1.5Zm-8.9-5.27 3.16-1.8-.74-1.3-3.16 1.8.74 1.3Zm-.5.2c.17-.04.34-.1.5-.2l-.74-1.3a.19.19 0 0 1-.06.02l.3 1.47Zm-3.96 1.71c0-.54.38-1 .9-1.1l-.28-1.48a2.63 2.63 0 0 0-2.12 2.58h1.5ZM.37 18.3c0 .94.76 1.7 1.69 1.7v-1.5a.2.2 0 0 1-.2-.2H.37Z' />
    <circle cx='8.18' cy='19.71' r='2.54' stroke='currentColor' strokeWidth='1.5' />
    <path fill='#FEB948' fillRule='evenodd' d='M36 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Zm1 8.2a1 1 0 1 0-2 0v.8a1 1 0 1 0 2 0v-.8Z' clipRule='evenodd' />
  </svg>
);
