/* eslint-disable max-len */
const Title = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#title-icon)' stroke='currentColor' strokeWidth='2'>
      <path d='M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3z' />
      <path strokeLinecap='round' strokeLinejoin='round' d='M9 10H5m6-4H5' />
    </g>
    <defs>
      <clipPath id='title-icon'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
);

export const TitleIssuesBad = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='34' height='27' fill='none' viewBox='0 0 34 27'>
    <path stroke='#183558' strokeLinecap='round' strokeWidth='1.5' d='M12.2 24.8H3.6a.6.6 0 0 1-.6-.6V4.3c0-.3.3-.5.6-.5H17l.4.1L20 6.7l.1.4v9.5' />
    <path stroke='#183558' strokeWidth='1.5' d='M16.5 4.3v3c0 .3.2.5.6.5H20' />
    <path stroke='#183558' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M6.8 11.3h9.7M6.8 15h9.7M6.8 18.8H12' />
    <path stroke='#183558' strokeLinecap='round' strokeWidth='1.5' d='m20 24.5 1.8 1.7' />
    <circle cx='17.6' cy='22.1' r='2.6' stroke='#183558' strokeWidth='1.5' />
    <path fill='#E03131' fillRule='evenodd' d='M33 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5c.6 0 1 .4 1 1v4a1 1 0 1 1-2 0V4c0-.6.4-1 1-1Zm1 8.2a1 1 0 1 0-2 0v.8a1 1 0 1 0 2 0v-.8Z' clipRule='evenodd' />
  </svg>
);

export const TitleIssuesGood = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='34' height='27' fill='none' viewBox='0 0 34 27'>
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='1.5' d='M12.2 24.75H3.57a.58.58 0 0 1-.57-.58V4.33c0-.32.26-.58.58-.58h13.38c.16 0 .3.06.41.17l2.72 2.81c.1.1.16.25.16.4v9.45' />
    <path stroke='currentColor' strokeWidth='1.5' d='M16.48 4.33v2.93c0 .32.26.58.59.58h2.92' />
    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M6.75 11.25h9.75M6.75 15h9.75M6.75 18.75H12' />
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='1.5' d='m20.08 24.5 1.67 1.68' />
    <circle cx='17.63' cy='22.13' r='2.63' stroke='currentColor' strokeWidth='1.5' />
    <g clipPath='url(#title-issue-check)'>
      <path fill='#2F9E44' fillRule='evenodd' d='M25.5 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.77-9.36a1 1 0 0 0-1.54-1.28L24.26 9.5l-1.03-1.1a1 1 0 0 0-1.46 1.38L22.94 11l.45.48.13.14.04.04v.01h.01l.73-.68-.73.69a1 1 0 0 0 1.5-.05l4.2-5Z' clipRule='evenodd' />
    </g>
    <defs>
      <clipPath id='title-issue-check'>
        <path fill='#fff' d='M0 0h16v16H0z' transform='translate(17.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default Title;
