import { toSeconds, convertSecondsToHoursMinutes } from '@src/utils/time';

const ENDED = 'ended';
const UPCOMING = 'upcoming';
const LIVE = 'live';
const FINALMINUTE = 'final minute';

export const states = { ENDED, UPCOMING, LIVE, FINALMINUTE };

/**
 * Calculates number of ticks that have elapsed
 * @param {Object} opts
 * @param {number} opts.startOfTimer - starting time for the timer UI in milliseconds
 * @param {number} opts.endOfTimer - ending time for the timer UI in milliseconds
 * @param {number} opts.currentTime - current time Eastern in milliseconds
 * @param {number} opts.numTicks - total number of ticks in the timer
 *
 * @returns {number} number of ticks elapsed
 *
 * @public
 */
export function ticksPassed({ startOfTimer, endOfTimer, currentTime, numTicks }) {
  const fromTime = Math.min(startOfTimer, endOfTimer);
  const toTime = Math.max(startOfTimer, endOfTimer);

  const totalWaitTime = (toTime - fromTime);
  const totalPassedTime = (currentTime - fromTime);
  const percentPassedTime = totalPassedTime / totalWaitTime;
  return numTicks * percentPassedTime;
}

/**
 * Returns the state of the timer including type of auction, start/end times and current time.
 * @param {Object} opts
 * @param {number} opts.startOfSale - starting time of sale from graph in milliseconds
 * @param {number} opts.endOfSale- ending time of sale from graph in milliseconds
 * @param {number} opts.isLive - true if the sale is currently happening
 * @returns {Object} state
 *
 * @public
 */
export function getTimerState({ startOfSale = 0, endOfSale = 0, isLive, currentTime, start }) {
  let startOfTimer = toSeconds(startOfSale);
  let endOfTimer = toSeconds(endOfSale);

  const hasAuctionEnded = !isLive && (currentTime > endOfTimer);
  const isUpcoming = !isLive && (currentTime < startOfTimer);
  const isLiveAuction = isLive && (!isUpcoming && !hasAuctionEnded);
  const isLastMinute = isLiveAuction && (endOfTimer - currentTime < 60);

  let state;
  if (hasAuctionEnded) {
    state = ENDED;
    endOfTimer = null;
    startOfTimer = null;
  } else if (isUpcoming) {
    endOfTimer = startOfTimer; // start of upcoming sale
    startOfTimer = start; // start of previous batch
    state = UPCOMING;
  } else if (isLiveAuction) {
    state = isLastMinute ? FINALMINUTE : LIVE;
  }

  return {
    state,
    startOfTimer,
    endOfTimer,
    currentTime
  };
}

/**
 * Returns the color used for tick marks and timer text
 * @param {string} state The state of the timer
 *
 * @returns {string} color key from the helios theme
 * @public
 */
export function getColorFromState(state) {
  const stateToColor = {
    [ENDED]: 'disabled',
    [UPCOMING]: 'primary',
    [LIVE]: 'success',
    [FINALMINUTE]: 'critical'
  };

  return stateToColor[state] ?? 'disabled';
}

/**
 * Returns the label for the timer
 * @param {string} state The state of the timer
 *
 * @returns {ReactElement} formatted message displaying the label
 *
 * @public
 */
export function getLabelFromState(state) {
  const label = {
    [ENDED]: 'Auction Ended:',
    [UPCOMING]: 'Time Until Live:',
    [LIVE]: 'Time Remaining:',
    [FINALMINUTE]: 'Time Remaining:'
  };
  return label[state] || '';
}

/**
 * Returns the time remaining in the auction
 * @param {Object} opts
 * @param {number} opts.startOfTimer - starting time of timer in seconds
 * @param {number} opts.endOfTimer- ending time of timer in seconds
 * @param {number} opts.currentTime - current time in seconds
 * @param {string} opts.state - state of the timer
 *
 * @returns {Object} Time to display on the timer
 * @returns {string} time.hours - hours to display on the timer
 * @returns {string} time.minutes - minutes to display on the timer
 * @returns {string} time.seconds - seconds to display on the timer
 *
 * @private
 */
export function getTimeRemaining({ state, startOfTimer, endOfTimer, currentTime }) {
  // sanity check to enforce that the timer is always
  // counting down positive numbers
  const toTime = Math.max(startOfTimer, endOfTimer);
  const timeUntil = toTime - currentTime; // Time left before the end
  if (state === ENDED || currentTime === 0 || timeUntil < 0) {
    return convertSecondsToHoursMinutes(0);
  }

  return convertSecondsToHoursMinutes(timeUntil);
}
