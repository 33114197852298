import PropTypes from 'prop-types';
import styled from 'styled-components';
import { label, reference } from '@carvana/helios-styles';

const Avatar = ({ letter, onClick }) => {
  return (
    <BackgroundCircle onClick={ onClick }>
      { letter }
    </BackgroundCircle>
  );
};

const BackgroundCircle = styled.button`
  ${label.sm};
  background: ${reference.yellow[400]};
  color: ${reference.blue[700]};
  text-transform: uppercase;
  border: none;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;

Avatar.defaultProps = {
  letter: '',
  onClick: () => {}
};

Avatar.propTypes = {
  letter: PropTypes.string,
  onClick: PropTypes.func
};

export default Avatar;
