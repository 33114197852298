import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import isSSR from '@src/utils/isSSR';
import createCompositeLink from './links/composite';

let _apolloClient;

export default function getApolloClient({ basePath = '', setSocketState } = {}) {
  const apolloClient = new ApolloClient({
    link: createCompositeLink({ basePath, setSocketState }),
    ssrMode: isSSR(),
    connectToDevTools: process.env.DEPLOY_ENV !== 'prod',
    cache: new InMemoryCache({
      typePolicies: {
        InventoryQuery: {
          fields: {
            byId: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Inventory',
                  id: args.id,
                });
              },
            },
            inventories: relayStylePagination(['$order', '$where']),
          },
          merge: true,
        },
        Listing: {
          keyFields: ['id'],
          merge: true
        },
      },
    }),
  });

  if (!isSSR()) {
    _apolloClient = _apolloClient ?? apolloClient;
    return _apolloClient;
  }

  return apolloClient;
}
