/* eslint-disable max-len */
const Menu = () => {
  return (
    <svg
      width='24'
      height='24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 17a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1zm0-5a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1zm.917-6a.917.917 0 1 0 0 1.833h18.166a.917.917 0 1 0 0-1.833H2.917z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Menu;
