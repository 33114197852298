import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { text } from '@carvana/helios-styles';
import useSaleEndsIn from '@hooks/useSaleEndsIn';
import isSSR from '@src/utils/isSSR';
import { mq, DESKTOP, flexAlignHorizontalContent } from '@src/styles/mixins';

import {
  states,
  getTimerState,
  getTimeRemaining,
  getColorFromState,
  getLabelFromState
} from './utils';
import TimerLabel from './TimerLabel';
import TimerText from './TimerText';

const MobileTimer = ({ listing }) => {
  const { startTime, endTime, isLive } = listing || {};
  const startOfSale = Date.parse(startTime);
  const endOfSale = Date.parse(endTime);
  const { start, currentTime } = useSaleEndsIn();
  const { state, startOfTimer, endOfTimer } = getTimerState({ startOfSale, endOfSale, isLive, currentTime, start });
  const isComingSoon = !startOfSale && !isLive && !endOfSale;
  const isListingLive = isLive || state === states.FINALMINUTE || state === states.LIVE;
  // TODO (gwall): Figure this out so we're only wrapping the progress bar counter
  // with a div, and don't need this logic in two places

  if (isComingSoon || isListingLive) {
    return (
      <Container>
        <TimerLabel label={ isComingSoon ? 'Coming soon!' : 'Listing live!' } />
      </Container>
    );
  }

  if (!startOfSale || (isLive && !endOfSale) || isSSR()) {
    return null;
  }

  return (
    <Container>
      <TimerText
        center
        label={ getLabelFromState(state) }
        color={ text[getColorFromState(state)] }
        time={ getTimeRemaining({ state, startOfTimer, endOfTimer, currentTime }) }
      />
    </Container>
  );
};

const Container = styled.div`
  ${flexAlignHorizontalContent('center', 'center')};
  ${mq(DESKTOP, 'display: none')};
  width: 100%;
  font-size: 14px;
  padding: 8px 0 12px;
`;

MobileTimer.propTypes = {
  listing: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isLive: PropTypes.bool
  })
};

export default MobileTimer;
