import React from 'react';
import { NextRouter, useRouter } from 'next/router';
import { UserProvider, UserProviderProps } from '@auth0/nextjs-auth0/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { IntlProvider } from 'react-intl';
import ThemeProvider from '@carvana/showroom-theme';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ApolloProvider } from '@apollo/client';

import getApolloClient from '../apollo/apolloClient';
import { ToastProvider } from '../contexts/ToastContext';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import {
  WebSocketProvider,
  WebSocketContext,
} from '../contexts/WebSocketContext';
import theme, { skeletonTheme } from '../styles/theme';
import * as shellLocales from '../content/locale';

import 'react-loading-skeleton/dist/skeleton.css';

function assertRouterLocale(
  locale: NextRouter['locale']
): asserts locale is string {
  if (!locale) {
    throw Error('NextRouter locale is not configured');
  }
}

type Props = {
  auth0User?: UserProviderProps['user'];
  auth0ProfileUrl?: string;
  auth0LoginUrl?: string;
  basePath?: string;
  ld?: {
    features: {
      [key: string]: any;
    };
    context: {
      [key: string]: any;
    };
  };
  locales?: {
    [locale: string]: {
      shared: {
        [key: string]: string;
      };
      [pathname: string]: {
        [key: string]: string;
      };
    };
  };
  children: React.ReactNode;
};

export default function AppProviders({
  auth0User,
  auth0ProfileUrl = '/api/auth/me',
  auth0LoginUrl = '/api/auth/login',
  basePath = '',
  children,
  ld,
  locales = shellLocales,
}: Props) {
  const router = useRouter();
  const { locale, defaultLocale, pathname } = router;
  assertRouterLocale(locale);
  const localeCopy = locales ? locales[locale] : { shared: {} };
  const messages = { ...localeCopy.shared, ...localeCopy[pathname] };

  return (
    <UserProvider
      user={ auth0User }
      loginUrl={ auth0LoginUrl }
      profileUrl={ auth0ProfileUrl }
    >
      <LDProvider
        clientSideID={ process.env.LD_CLIENT_ID ?? 'missing LD ID' }
        reactOptions={{
          useCamelCaseFlagKeys: true,
        }}
        context={ ld?.context || {} }
        options={{
          bootstrap: ld?.features || {},
        }}
      >
        <ThemeProvider theme={ theme }>
          <SkeletonTheme { ...skeletonTheme }>
            <WebSocketProvider>
              <WebSocketContext.Consumer>
                {({ setSocketState }) => (
                  <ApolloProvider
                    client={ getApolloClient({ basePath, setSocketState }) }
                  >
                    <IntlProvider
                      locale={ locale }
                      defaultLocale={ defaultLocale }
                      messages={ messages }
                    >
                      <AnalyticsProvider
                        writeKey={ process.env.CARVANA_ACCESS_SEGMENT_KEY }
                      >
                        <ToastProvider>{children}</ToastProvider>
                      </AnalyticsProvider>
                    </IntlProvider>
                  </ApolloProvider>
                )}
              </WebSocketContext.Consumer>
            </WebSocketProvider>
          </SkeletonTheme>
        </ThemeProvider>
      </LDProvider>
    </UserProvider>
  );
}
