import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { border } from '@carvana/helios-styles';
import { mq, LARGE_MOBILE } from '../styles/mixins';

const Horizontal = styled.div`
  border-top: 1px solid ${border.weak};
  margin: ${({ margin = '55px auto' }) => margin};
  width: ${({ width = '100%' }) => width};
  ${({ hideOnMobile }) => hideOnMobile && mq(LARGE_MOBILE, 'display: none;')}
`;

const Vertical = styled.div`
  border-right: 1px solid ${border.weak};
  margin: ${({ margin = 'auto 32px' }) => margin};
  height: ${({ height = '24px' }) => height};
`;

const directions = {
  horizontal: Horizontal,
  vertical: Vertical
};

const GrayLine = ({ direction, ...rest }) => {
  const Line = directions[direction];

  return <Line { ...rest } />;
};

GrayLine.defaultProps = {
  direction: 'horizontal'
};

GrayLine.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical'])
};

export default GrayLine;
