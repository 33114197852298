/* eslint-disable max-len */
const Target = () => (
  <svg width='56' height='56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.221 17.223a19.926 19.926 0 0 0-5.093 13.334c0 11.045 8.955 20 20 20 11.046 0 20-8.955 20-20 0-11.046-8.954-20-20-20-4.115 0-7.94 1.243-11.12 3.374' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    <path d='M18.395 21.567a13.943 13.943 0 0 0-3.267 8.99c0 7.732 6.268 14 14 14s14-6.268 14-14-6.268-14-14-14a13.944 13.944 0 0 0-6.752 1.733' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    <path d='M22.796 25.668a8 8 0 1 0 4.333-2.86' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    <circle cx='29.128' cy='30.557' r='3' fill='#00619E' />
    <path d='m29.128 30.557-16-16' stroke='#00619E' strokeWidth='2' />
    <path d='M13.128 14.557v1h1v-1h-1zm0-4h1a1 1 0 0 0-.293-.707l-.707.707zm-4-4 .708-.707a1 1 0 0 0-1.656.39l.948.317zm-1 3 .317.948.474-.158.158-.474-.949-.316zm-3 1-.316-.949a1 1 0 0 0-.39 1.656l.706-.707zm4 4-.707.707a1 1 0 0 0 .707.293v-1zm5 0v-4h-2v4h2zm-.293-4.707-4-4-1.414 1.414 4 4 1.415-1.414zM8.18 6.24l-1 3 1.897.633 1-3L8.18 6.24zm-.368 2.368-3 1 .633 1.897 3-1-.633-1.897zm-3.39 2.656 4 4 1.414-1.414-4-4-1.415 1.414zm4.706 4.293h4v-2h-4v2z' fill='#00619E' />
    <path fillRule='evenodd' clipRule='evenodd' d='M17.996 10 13.86 6.068a2.994 2.994 0 0 1 0-4.199A2.902 2.902 0 0 1 15.937 1 2.9 2.9 0 0 1 18 1.855a2.921 2.921 0 0 1 4.14.014 2.993 2.993 0 0 1 0 4.197L17.997 10z' fill='#00AED9' />
  </svg>
);

export default Target;
