/* eslint-disable max-len */
export const en = {
  // This contains messages that exist on all pages (header and footer)
  'shared': {
    APPLY: 'apply.',
    AND: 'and',
    CARVANA_AUCTION: 'Carvana Auction',
    CARVANA_ACCESS: 'CarvanaACCESS',
    CONTACT_US: 'Contact Us',
    COPY_SUCCESS: 'Copied {text}',
    COPY_FAILED: 'Failed to copy {text}',
    HELP: 'HELP',
    LEARN_MORE: 'Learn More',
    MENU: 'MENU',
    MORE_QUESTIONS: 'More questions?',
    OR: 'or',
    PRIVACY_POLICY: 'Privacy Policy',
    SIGN_IN: 'Sign in',
    MANHEIM_SIGN_IN: 'Sign In with Manheim',
    ADESA_SIGN_IN: 'Sign In with Adesa',
    UNKNOWN: 'UNKNOWN',
    OPERABLE: 'OPERABLE',
    INOPERABLE: 'INOP',
    PAGE_NOT_FOUND_TITLE: 'Oops! Page not found!',
    NOT_FOUND_REPORTS: 'Above & beyond condition reports',
    NOT_FOUND_TRANSACTIONS: 'Hassle-free transactions',
    NOT_FOUND_CONVENIENCE: 'Convenience you never knew you needed',
    OPENING_BID: 'Opening Bid',
    CURRENT_BID: 'Current Bid',
    MOBILE_DOWNLOAD_TEXT: 'Get the full experience with the Carvana Auction app.',
    DOWNLOAD_APP: 'DOWNLOAD THE APP',
    HOURS: 'Hrs',
    MINUTES: 'Min',
    SECONDS: 'Sec',
  },
  '/': {
    ACCESS_RUN_LIST: 'Access Run List',
    BID_WITH_MANHEIM: 'Bid with Manheim',
    BUY_WHOLESALE: 'Do you buy wholesale vehicles for a used car dealership?',
    DAILY_SALES_EVERY_WEEKDAY: 'We offer daily sales ending every weekday at 4PM ET ({time} Local).',
    GET_DIRECT_ACCESS: 'Get direct access to Carvana\'s most recent trade-ins.',
    CARVANA_ONLINE_AUCTION: 'Carvana\'s Online Auction For Dealers',
    EXCLUSIVE_RUN_LIST_TITLE: 'Exclusive Run List',
    EXCLUSIVE_RUN_LIST_TEXT: 'Visit our run list to preview wholesale vehicles 24 hours before bidding begins.',
    LEARN_MORE: 'Learn More',
    TODAYS_SALE: 'Today\'s Sale',
    UNTIL_SALE_ENDS: 'Until Our Current Sale Ends'
  },
  '/details/[id]': {
    GO_BACK: 'Go Back',
    BID_ON_MANHEIM: 'Bid on Manheim.com',
    ADJUSTED_MMR: 'Adjusted MMR: ',
    ODOMETER_UNIT: 'miles',
    VIN: 'VIN:',
    HISTORY: 'History',
    EQUIPMENT: 'Equipment',
    OVERVIEW: 'Overview',
    NO_IMAGE_AVAILABLE: 'NO IMAGE AVAILABLE',
    ADD_TO_WATCHLIST: 'Add to Watchlist',
    ADDED_TO_WATCHLIST: 'Added to Watchlist',
    COMING_SOON: 'Coming soon!',
    LISTING_LIVE: 'Listing live!',

    // History
    OWNERS: '{numOwners, plural, =0 {# Owners} one {# Owner} other {# Owners}}',
    ACCIDENTS: '{numAccidents, plural, =0 {# Accidents} one {# Accident} other {# Accidents}}',
    ODO_ISSUES: 'Has Odo Issue(s)',
    NO_ODO_ISSUES: 'No Odo Issues',
    TITLE_ISSUES: 'Has Title Issue(s)',
    NO_TITLE_ISSUES: 'No Title Issues',
    USAGE_ISSUES: 'Non-Personal Use',
    NO_USAGE_ISSUES: 'Personal Use',

    // Overview titles
    EXTERIOR_COLOR: 'Exterior Color',
    TRANSMISSION_TYPE: 'Transmission Type',

    // not found
    LISTING_NOT_FOUND_TITLE: 'Oops, listing not found!',
    NOT_FOUND_MESSAGE: 'Looks like that {content} doesn\'t exist!',
  },
  '/browse/[[...slug]]': {
    BROWSE_TITLE: 'Browse',
    NO_IMAGE: 'NO IMAGE',
    NO_SEARCH_RESULT_MATCH: 'Uh oh, there are no listings in today’s run list that match your filters.',
    REMOVE_FILTERS: 'Remove filters to widen your search.',
    NO_LISTINGS_FOUND: 'No listings found.',
    NO_LISTINGS_FOUND_SUPPORT: 'No vehicles have been added to the run list for the next sale yet. Please check back later or contact support.',
    NO_FILTER_RESULTS: 'No {category} match your search',
    NO_WATCHLIST_RESULTS: 'Uh oh, looks like you don\'t have any listings on your watchlist.',
    WATCHLIST_INSTRUCTIONS: 'Hit the star icon on the bottom right corner of a listing to add it to your watchlist.',
    TRY_AGAIN: 'Try a different search',
    FILTERS: 'Filters',
    RESULTS: 'Results',
    MAKE: '{count, plural, =0 {no makes} one {Make} other {makes}}',
    MODEL: '{count, plural, =0 {no models} one {Model} other {models}}',
    YEAR: 'Year',
    PICKUP_LOCATION: '{count, plural, =0 {no locations} one {Pickup Location} other {locations}}',
    CR_GRADE: 'CR Grade',
    CR: 'CR',
    OPEN_BID: 'Opening Bid',
    PRICE: 'Price',
    ODOMETER: 'Odometer',
    ODO: 'ODO',
    WATCHLISTED: 'Watchlisted',
    FILTER_PANEL_PILL: '{state} Filters',
    CLOSE: 'Close',
    CLEAR_ALL: 'Clear All',
    CLEAR_ALL_FILTERS: 'Clear all filters',
    CLEAR_WATCHLIST_FILTER: 'Clear watchlist filter',
    CONTACT_SUPPORT: 'Contact support',
    APPLY_FILTERS: 'Apply Filters',
    MIN_FILTER_LABEL: 'Min {label}',
    MAX_FILTER_LABEL: 'Max {label}',
    MORE: 'More',
    HIDE: 'Hide',
    REMOVE: 'Remove',
    SHOW: 'Show',
    SORT_BY: 'Sort by',

    OLDEST: 'Oldest Available',
    NEWEST: 'Newest Available',
    LOWEST: 'Lowest Available',
    HIGHEST: 'Highest Available',
    HIGHEST_CR_GRADE: 'Highest CR Grade',
    LOWEST_CR_GRADE: 'Lowest CR Grade',
    HIGHEST_OPENING_BID: 'Highest Opening Bid',
    LOWEST_OPENING_BID: 'Lowest Opening Bid',

    // SRP Timeout Modal
    TIMEOUT_HEADER: 'This sale is now live!',
    TIMEOUT_TITLE: 'Our listings are now live!',
    TIMEOUT_BODY: 'These listings are no longer on the run list because they are now live for bidding through Manheim. Sign into CarvanaACCESS with your Manheim account to browse today\'s sale.',

    SEARCH_MAKE: 'Search by make',
    SEARCH_MODEL: 'Search by model',
    SEARCH_LOCATION: 'Search by location',

    // Today's Sale
    TODAYS_SALE_TITLE: 'Sign in with Manheim to Access Live Sale!',
    TODAYS_SALE_SUB_TITLE: 'Carvana Auction partners with Manheim to provide our digital auction platform. Sign in with an existing Manheim digital account to bid on and purchase Carvana wholesale vehicles.',
    TODAYS_SALE_LINK: 'Don\'t have a Manheim account? Go {link} to sign up',
    HERE: 'here',

    WATCHLIST: 'Watchlist'
  }
};

export default en;
