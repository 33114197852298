import { datadogRum } from '@datadog/browser-rum';
import isbot from 'isbot';
import pkg from '../../package.json';
import isSSR from '../utils/isSSR';

let isInitialized = false;

export default function useDataDog(user = {}) {
  if (!isInitialized && !isSSR() && process.env.DEPLOY_ENV) {
    const sampleRate = isbot(navigator.userAgent) ? 0 : 100;
    isInitialized = true;

    datadogRum.init({
      applicationId: '499db5ae-1c80-49cc-9718-fc3023b29738',
      clientToken: 'pub0fa22b44fd1cb47908467a07a4e8cd30',
      site: 'datadoghq.com',
      service: 'wholesale.runlist.ui',
      env: process.env.DEPLOY_ENV,
      version: pkg.version,
      sampleRate,
      sessionReplaySampleRate: 50,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      beforeSend: (event) => {
        if (event.error?.message === 'Promise timed out') { // the exact error message for segment timeout
          return false;
        }
        event.context = {
          ...event.context,
          userId: user.sub,
        };
        return undefined;
      },
    });
    datadogRum.startSessionReplayRecording();
  }
}
