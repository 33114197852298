import PropTypes from 'prop-types';

const rotation = {
  left: 0,
  up: 90,
  right: 180,
  down: 270
};

const Caret = ({ direction, size }) => {
  const halfSize = size * 0.5;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={ size } height={ size } fill='none'>
      <path
        transform={ `rotate(${rotation[direction]}, ${halfSize}, ${halfSize})` }
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M11 2 4 8l7 6'
      />
    </svg>
  );
};

Caret.defaultProps = {
  size: 16,
  direction: 'left'
};

Caret.propTypes = {
  size: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down'])
};

export default Caret;
