import { useEffect, useState } from 'react';
import { cidForSession } from '../utils/utm';

const useCid = (uri, defaultCid = '') => {
  const url = new URL(uri);
  const [cid, setCid] = useState('');

  useEffect(() => setCid(cidForSession()), []);

  if (cid || defaultCid) {
    url.searchParams.set('cid', `${cid}${cid && defaultCid ? '-' : ''}${defaultCid}`);
  }

  return url;
};

export default useCid;
