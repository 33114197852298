import { CarvanaTheme } from '@carvana/showroom-theme';

const theme = {
  ...CarvanaTheme,
  app: {
    colors: {
      validation: {
        success: '#20A91E',
        warning: '#FEB948',
        error: '#E03131'
      },
    }
  }
};

export const skeletonTheme = {
  baseColor: '#F4F8FA',
  borderRadius: 0,
  highlightColor: '#FCFCFC'
};

export default theme;
