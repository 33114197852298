/* eslint-disable max-len */
import React from 'react';

const Stopwatch = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='28' cy='31.27' r='20' stroke='#00619E' strokeWidth='2' />
      <path d='M37.798 39.815c.416.363.462.997.07 1.387a13.998 13.998 0 0 1-22.638-4.193 14.001 14.001 0 0 1 11.896-19.711c.551-.035.995.42.99.973l-.116 13 9.798 8.544Z' fill='#00AED9' />
      <path d='M28 4.654v6' stroke='#00619E' strokeWidth='2' />
      <path d='M23 4.654h10' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
      <path d='m11.094 14.7 2.829 2.829' stroke='#00619E' strokeWidth='2' />
      <path d='m8.277 16.125 4.242-4.243' stroke='#00619E' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default Stopwatch;
