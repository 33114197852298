import PropTypes from 'prop-types';

const SmallArrow = ({ direction }) => {
  const rotation = {
    up: 0,
    down: 180
  };

  return (
    <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 10H3l5-5.5 5 5.5z'
        fill='currentColor'
        transform={ `rotate(${rotation[direction]}, 8, 8)` }
      />
    </svg>
  );
};

SmallArrow.defaultProps = {
  direction: 'down'
};

SmallArrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down'])
};

export default SmallArrow;
