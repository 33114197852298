import PropTypes from 'prop-types';
import styled from 'styled-components';
import { text } from '@carvana/helios-styles';

import { flexAlignHorizontalContent, mq } from '../../styles/mixins';

const TimerLabel = ({ label }) => (
  <Label>
    {label}
  </Label>
);

TimerLabel.propTypes = {
  label: PropTypes.string.isRequired
};

const Label = styled.p`
  ${flexAlignHorizontalContent('center', 'center')};
  ${mq('desktop', 'font-size: 20px;')}
  ${mq('largeMobile', 'width: 100%;')}
  font-weight: 700;
  color: ${text.default};
`;

export default TimerLabel;
