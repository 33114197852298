/* eslint-disable max-len */
const Recall = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 32 32'>
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='2' d='M23.8 10H7.3C4.4 10 2 12.4 2 15.4V22h9.2' />
    <path stroke='currentColor' strokeWidth='2' d='M12.2 2h6.7C21.6 2 24 4.4 24 7.4V10H7V7.4C7 4.4 9.4 2 12.2 2Z' />
    <path fill='currentColor' d='M29 8.6c0 .6-.4 1.2-1 1.4l-1.7.8c-1.5.6-3.3-.3-3.3-1.8 0-1.1 1-2 2.3-2h2.1c.9 0 1.6.7 1.6 1.6Zm-27 0c0 .6.4 1.2 1 1.4l1.6.8C6 11.5 7 10.5 7 9c0-1.1-.2-2-1.4-2h-2C2.5 7 2 7.7 2 8.6ZM1 23a3 3 0 0 0 3.1 3H5A3 3 0 0 0 8 23H1Z' />
    <path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='2' d='M12 16h3' />
    <path fill='currentColor' d='M7 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' />
    <path stroke='currentColor' strokeLinejoin='round' strokeWidth='2' d='M11.7 30 21 14l4.6 8 4.7 8H11.7Z' />
    <circle cx='21' cy='27' r='1' fill='currentColor' />
    <circle cx='21' cy='27' r='1' stroke='currentColor' />
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='2' d='M21 21v3' />
  </svg>
);

export default Recall;
