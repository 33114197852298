/* eslint-disable max-len */
const MoneyKeys = () => (
  <svg width='56' height='56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.053 21.02a4.721 4.721 0 0 1-4.721 4.722m4.827 7.955a4.721 4.721 0 0 0-4.722-4.722' stroke='#00619E' strokeWidth='2' />
    <path d='M16.628 29.8C15.71 29.137 15 28.033 15 26.262c0-1.816.64-3.302 1.572-4.026M24 20.404c.917.663 1.628 1.767 1.628 3.538 0 1.816-.64 3.303-1.572 4.026' stroke='#00AED9' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.117 27.354c-.07-.07-.224-.347 0-.736.225-.389.555-.298.64-.236.238.16.666.458 1.193.458.337 0 .583-.208.583-.5 0-.347-.31-.576-.871-.84-.807-.396-1.629-.84-1.629-1.84 0-.826.653-1.66 1.945-1.66.878 0 1.552.445 1.804.625.099.056.035.445-.119.653-.154.208-.463.41-.632.313-.19-.132-.688-.452-1.137-.452-.351 0-.513.215-.513.459 0 .277.33.492.92.777.744.361 1.699.764 1.699 1.896 0 .861-.751 1.722-1.987 1.722-1.095.007-1.7-.444-1.896-.639Z' fill='#00AED9' />
    <rect x='19.504' y='21' width='.999' height='2' rx='.499' fill='#00AED9' /><rect x='19.504' y='27' width='.999' height='2' rx='.499' fill='#00AED9' />
    <path d='M29 29.616a40.71 40.71 0 0 0-3.37.585c-.066.015-.13.03-.196.05-.986.276-9.282 2.592-12.142 3.11-2.219.4-3.707.768-4.676 1.054C7.879 34.63 7 34.046 7 33.279V21.92c0-.539.426-.984.963-1.025a51.27 51.27 0 0 0 6.776-.991c4.467-.966 8.136-2.219 11.005-2.904' stroke='#00619E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='m10.886 38 .006-1-.006 1Zm6.25-.64.174.984-.174-.985Zm11.882-2.986-.265-.964.265.964ZM11 36.552a1 1 0 1 0-2 0h2Zm18.21-2.226-.212-.977.213.977Zm1.942.663a1 1 0 1 0-.304-1.977l.304 1.977Zm-20.273 4.01c1.13.008 3.238-.089 6.431-.655l-.349-1.97c-3.083.547-5.069.632-6.07.626l-.012 2Zm6.431-.655c2.857-.507 11.031-2.747 11.973-3.006l-.53-1.928c-.997.274-9.052 2.478-11.792 2.964l.35 1.97ZM9 36.552v.522h2v-.522H9Zm20.423-1.248a28.906 28.906 0 0 1 1.73-.315l-.305-1.977c-.636.098-1.264.21-1.85.337l.425 1.955Zm-.14.034c.05-.013.095-.025.14-.035l-.425-1.954a4.676 4.676 0 0 0-.245.06l.53 1.93ZM10.892 37a.13.13 0 0 1 .09.04c.018.02.018.036.018.034H9c0 1.005.773 1.919 1.88 1.926l.012-2Z' fill='#00619E' />
    <path fillRule='evenodd' clipRule='evenodd' d='M35.66 15.465a5.53 5.53 0 0 0-5.532 5.531c0 2.786 1.825 5.225 4.285 5.702l.021-.004 2.835 13.382a1 1 0 0 0 .376.591l1.83 1.38.746-1.583a3 3 0 0 0 .209-1.96l-1.164-5.003a3 3 0 0 1 .08-1.64l.496-1.47a1 1 0 0 0 .02-.57l-.97-3.768-.012-.02a.356.356 0 0 1 .007-.003l-.002-.005.015-.004c1.411-.928 2.29-2.954 2.29-5.025a5.53 5.53 0 0 0-5.53-5.53Zm5.47 11.257c1.383-1.516 2.06-3.71 2.06-5.726a7.53 7.53 0 1 0-15.062 0c0 3.114 1.783 6.098 4.595 7.27l2.59 12.225a3 3 0 0 0 1.127 1.773l1.83 1.38a2 2 0 0 0 3.013-.743l.747-1.584a5 5 0 0 0 .348-3.265l-1.164-5.005a1 1 0 0 1 .026-.546l.497-1.47a3 3 0 0 0 .063-1.708l-.67-2.601Zm-5.656-5.712a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' fill='#00619E' />
    <path fillRule='evenodd' clipRule='evenodd' d='M35.66 15.465a5.53 5.53 0 0 0-5.532 5.531c0 2.786 1.825 5.225 4.285 5.702l.021-.004 2.835 13.382a1 1 0 0 0 .376.591l1.83 1.38.746-1.583a3 3 0 0 0 .209-1.96l-1.164-5.003a3 3 0 0 1 .08-1.64l.496-1.47a1 1 0 0 0 .02-.57l-.97-3.768-.012-.02a.356.356 0 0 1 .007-.003l-.002-.005.015-.004c1.411-.928 2.29-2.954 2.29-5.025a5.53 5.53 0 0 0-5.53-5.53Zm5.47 11.257c1.383-1.516 2.06-3.71 2.06-5.726a7.53 7.53 0 1 0-15.062 0c0 3.114 1.783 6.098 4.595 7.27l2.59 12.225a3 3 0 0 0 1.127 1.773l1.83 1.38a2 2 0 0 0 3.013-.743l.747-1.584a5 5 0 0 0 .348-3.265l-1.164-5.005a1 1 0 0 1 .026-.546l.497-1.47a3 3 0 0 0 .063-1.708l-.67-2.601Zm-5.656-5.712a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' fill='#00AED9' />
    <path d='M45.194 37.387a1 1 0 0 0-1.53 1.29l1.53-1.29Zm-.338-9.608a1 1 0 0 0-1.455 1.372l1.455-1.372Zm-.154 1.294-.728.686.728-.686Zm4.242 8.307-.998-.059.998.06Zm-.374-1.287-.811.584.81-.584Zm.29 2.713-.999-.06.999.06Zm-2.626.659-.18.984.18-.984Zm-2.57-.789.637.756 1.53-1.29-.637-.755-1.53 1.29Zm2.39 1.773 1.447.265.36-1.967-1.446-.266-.361 1.968Zm3.804-1.584.084-1.425-1.996-.119-.085 1.426 1.997.118Zm-.477-3.357-2.868-3.978-1.622 1.17 2.868 3.977 1.622-1.17Zm-3.05-4.455-.103-.933-1.988.217.102.933 1.988-.216Zm-.902-2.665-.573-.609-1.455 1.372.573.608 1.455-1.371Zm.8 1.732a3 3 0 0 0-.8-1.732l-1.455 1.371a1 1 0 0 1 .266.578l1.988-.217Zm.284 1.41a1 1 0 0 1-.183-.477l-1.988.217a3 3 0 0 0 .549 1.43l1.622-1.17Zm3.43 5.91a3 3 0 0 0-.562-1.932l-1.622 1.17a1 1 0 0 1 .187.643l1.996.119ZM47.5 40.714a2 2 0 0 0 2.357-1.849l-1.997-.118-.36 1.967Zm-3.2-1.282a3 3 0 0 0 1.753 1.017l.36-1.968a1 1 0 0 1-.583-.339l-1.53 1.29Z' fill='#00AED9' />
  </svg>
);

export default MoneyKeys;
