import PropTypes from 'prop-types';
import styled from 'styled-components';
import { background } from '@carvana/helios-styles';

const ProgressBar = ({ percentFull, color }) => (
  <Meter>
    <Bar $color={ color } $percentage={ percentFull } />
  </Meter>
);

ProgressBar.propTypes = {
  color: PropTypes.string,
  percentFull: PropTypes.number,
};

const Meter = styled.div`
  width: 100%;
  height: 8px;
  position: relative;
  background: ${background.disabled};
  margin-top: 8px;
  border-radius: 4px;
`;

const Bar = styled.span`
  background-color: ${({ $color }) => $color};
  width: ${({ $percentage }) => $percentage}%;
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
`;

export default ProgressBar;
