import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  
  > div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

const MaxContentWrapper = ({ width, children }) => {
  return (
    <Wrapper>
      <div style={{ maxWidth: `${width}px` }}>
        {children}
      </div>
    </Wrapper>
  );
};

MaxContentWrapper.defaultProps = {
  width: 1440
};

MaxContentWrapper.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node.isRequired
};

export default MaxContentWrapper;
