import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';

import AuthHeader from './AuthHeader';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  const { pathname } = useRouter();
  const { user } = useUser();

  if (['/download'].includes(pathname)) {
    return children;
  }

  if (!user || pathname === '/') {
    return (
      <LayoutWrapper>
        <Header />
        {children}
        <Footer />
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper>
      <AuthHeader />
      { children }
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
