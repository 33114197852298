import { ApolloLink, Observable } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';

const logLink = new ApolloLink((operation, forward) => {
  const observable = forward(operation);

  return new Observable((observer) => {
    const subscription = observable.subscribe({
      next: (value) => {
        // eslint-disable-next-line no-unused-vars
        Object.entries(value.data || {}).forEach(([req, res]) => {
          if (res?.__typename?.includes('Error')) {
            const definition = getMainDefinition(operation.query);
            const error = new Error(
              res.message || `A graphql error has occured: ${res.__typename}`
            );
            datadogRum.addError(error, {
              request: {
                operationType: definition.operation,
                operationName: definition.name?.value,
              },
            });
          }
        });
        observer.next(value);
      },
      error: observer.error.bind(observer),
      complete: observer.complete.bind(observer),
    });

    return () => {
      subscription.unsubscribe();
    };
  });
});

export default logLink;
