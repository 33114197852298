/* eslint-disable max-len */
export const OdoGood = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='35' height='27' fill='none' viewBox='0 0 33 27'>
    <rect width='22.5' height='5.25' x='.75' y='20.25' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' rx='.75' />
    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M13.5 23.25v-.75M7.5 23.25v-.75M16.5 23.25v-.75M10.5 23.25v-.75' />
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='1.5' d='M22.26 17.25a10.5 10.5 0 1 0-20.52 0M9.08 12l4.39 4.4' />
    <circle cx='11.87' cy='14.82' r='.75' fill='currentColor' stroke='currentColor' strokeWidth='1.5' transform='rotate(-45 11.87 14.82)' />
    <path fill='currentColor' d='M11.25 6.1a.75.75 0 0 0 1.5 0h-1.5Zm0-1.5v1.5h1.5V4.6h-1.5ZM15.8 6.98a.75.75 0 0 0 1.3.75l-1.3-.75Zm.75-1.3-.75 1.3 1.3.75.75-1.3-1.3-.75ZM8.2 6.98a.75.75 0 0 1-1.3.75l1.3-.75Zm-.75-1.3.75 1.3-1.3.75-.74-1.3 1.3-.75ZM19.23 9.85a.75.75 0 0 0 .75 1.3l-.75-1.3Zm1.3-.75-1.3.75.75 1.3 1.3-.75-.75-1.3ZM4.78 9.85a.75.75 0 1 1-.75 1.3l.75-1.3Zm-1.3-.75 1.3.75-.75 1.3-1.3-.75.75-1.3ZM3.7 13.55a.75.75 0 0 1 0 1.5v-1.5Zm-1.5 0h1.5v1.5H2.2v-1.5ZM20.3 13.6a.7.7 0 1 0 0 1.4v-1.4Zm1.5 0h-1.5V15h1.5v-1.4Z' />
    <g clipPath='url(#odo-check)'>
      <path fill='#2F9E44' fillRule='evenodd' d='M25.5 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.77-9.36a1 1 0 0 0-1.54-1.28L24.26 9.5l-1.03-1.1a1 1 0 0 0-1.46 1.38L22.94 11l.45.48.13.14.04.04v.01h.01l.73-.68-.73.69a1 1 0 0 0 1.5-.05l4.2-5Z' clipRule='evenodd' />
    </g>
    <defs>
      <clipPath id='odo-check'>
        <path fill='#fff' d='M0 0h16v16H0z' transform='translate(17.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const OdoBad = () => (
  <svg style={{ marginTop: -3 }} xmlns='http://www.w3.org/2000/svg' width='35' height='27' fill='none' viewBox='0 0 33 27'>
    <rect width='22.5' height='5.25' x='.75' y='20.25' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' rx='.75' />
    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M13.5 23.25v-.75M7.5 23.25v-.75M16.5 23.25v-.75M10.5 23.25v-.75' />
    <path stroke='currentColor' strokeLinecap='round' strokeWidth='1.5' d='M22.26 17.25a10.5 10.5 0 1 0-20.52 0M9.08 12l4.39 4.4' />
    <circle cx='11.87' cy='14.82' r='.75' fill='currentColor' stroke='currentColor' strokeWidth='1.5' transform='rotate(-45 11.87 14.82)' />
    <path fill='currentColor' d='M11.25 6.1a.75.75 0 0 0 1.5 0h-1.5Zm0-1.5v1.5h1.5V4.6h-1.5ZM15.8 6.98a.75.75 0 0 0 1.3.75l-1.3-.75Zm.75-1.3-.75 1.3 1.3.75.75-1.3-1.3-.75ZM8.2 6.98a.75.75 0 0 1-1.3.75l1.3-.75Zm-.75-1.3.75 1.3-1.3.75-.74-1.3 1.3-.75ZM19.23 9.85a.75.75 0 0 0 .75 1.3l-.75-1.3Zm1.3-.75-1.3.75.75 1.3 1.3-.75-.75-1.3ZM4.78 9.85a.75.75 0 1 1-.75 1.3l.75-1.3Zm-1.3-.75 1.3.75-.75 1.3-1.3-.75.75-1.3ZM3.7 13.55a.75.75 0 0 1 0 1.5v-1.5Zm-1.5 0h1.5v1.5H2.2v-1.5ZM20.3 13.6a.7.7 0 1 0 0 1.4v-1.4Zm1.5 0h-1.5V15h1.5v-1.4Z' />
    <path fill='#E03131' fillRule='evenodd' d='M33 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Zm1 8.2a1 1 0 1 0-2 0v.8a1 1 0 1 0 2 0v-.8Z' clipRule='evenodd' />
  </svg>
);
