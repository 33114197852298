/* eslint-disable max-len */
const Pin = () => (
  <svg role='img' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
    <path
      fill='currentColor'
      d='M8 1V0h-.01L8 1zm.58 13.66-.72-.68-.06.05-.04.07.82.56zM8 15l-.09 1H8.08L8 15zm-.58-.31.78-.62-.03-.04-.03-.03-.72.69zM3 5.94l-1-.01v.01h1zM8.01 5l.02-1h-.01v1zM9 6l1 .02L9 6zM7 6H6h1zm1 1-.01 1V7zm6-1.06C14 2.65 11.3 0 8 0v2c2.22 0 4 1.78 4 3.94h2zm-5.42 8.72.73.69.02-.02a9.67 9.67 0 0 0 .19-.21 25 25 0 0 0 2.1-2.75C12.76 10.68 14 8.29 14 5.95h-2c0 1.72-.96 3.69-2.04 5.33a22.99 22.99 0 0 1-2.06 2.66 7.82 7.82 0 0 1-.04.04l.72.7zM8.08 16a1.78 1.78 0 0 0 1.33-.77L7.76 14.1a.21.21 0 0 1 .16-.1l.16 2zm-1.45-.7c.32.4.78.65 1.28.7l.18-2a.16.16 0 0 1 .11.07L6.63 15.3zM2 5.95c0 2.34 1.25 4.74 2.37 6.45a25.2 25.2 0 0 0 2.1 2.75 14.24 14.24 0 0 0 .2.22h.01a.2.2 0 0 1 0 .01l.74-.68a318.23 318.23 0 0 1 .72-.69.5.5 0 0 1-.04-.04l-.13-.15a23.18 23.18 0 0 1-1.93-2.52C4.96 9.64 4 7.66 4 5.94H2zM7.99 0A6.03 6.03 0 0 0 2 5.93l2 .02A4.03 4.03 0 0 1 8.01 2l-.02-2zM8 6l2 .02A2 2 0 0 0 8.03 4L8 6zm0 0 .02-2A2 2 0 0 0 6 6h2zm0 0H6a2 2 0 0 0 2 2V6zm0 0v2a2 2 0 0 0 2-1.98L8 6z'
    />
  </svg>
);

export default Pin;
