/* eslint-disable max-len */
import React from 'react';

const ErrorOutlined = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zM12 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm0 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' fill='currentColor' />
  </svg>
);

export default ErrorOutlined;
