import { useFlags } from 'launchdarkly-react-client-sdk';
import { easternTime, saleStartEasternTime, toSeconds, defaultSaleEndTime } from '../utils/time';
import useIntervalRefresh from './useIntervalRefresh';

const WeekDays = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

export default function useSaleEndsIn(offsetMinutes = 0) {
  const { currentTime: currentTimeInMilis, clearInterval, restartTimer } = useIntervalRefresh(1000, offsetMinutes);
  const { auctionSaleEndTime } = useFlags();
  const [start, end] = getSaleTimes(currentTimeInMilis, auctionSaleEndTime);
  const currentTime = toSeconds(currentTimeInMilis);

  return { start, end, currentTime, clearInterval, restartTimer };
}

const getSaleTimes = (time, saleEnd) => {
  saleEnd = saleEnd || defaultSaleEndTime;
  let date = easternTime(time);
  if (date.get('minute') + (date.get('hour') * 60) < saleEnd.minute + (saleEnd.hour * 60)) {
    date = date.minus({ days: 1 });
  }

  const { weekday } = date;
  const endTime = saleStartEasternTime(date.toMillis(), saleEnd);

  if ([WeekDays.SUNDAY, WeekDays.FRIDAY, WeekDays.SATURDAY].includes(weekday)) {
    // sale ends monday at 4 eastern
    const offset = WeekDays.MONDAY + ((7 - weekday) % 7);
    const monday = endTime.plus({ days: offset });

    // weekend sale goes from friday@4PM to monday@3:59PM
    const endSeconds = toSeconds(monday.toMillis()) - 1;
    const startSeconds = toSeconds(monday.minus({ days: 3 }).toMillis());
    return [startSeconds, endSeconds];
  }

  // sale ends tomorrow at 4 eastern
  const startSeconds = toSeconds(endTime.toMillis());
  const endSeconds = toSeconds(endTime.plus({ days: 1 })) - 1;
  return [startSeconds, endSeconds];
};
