export const flexAlignVerticalContent = (x, y) => `
  display: flex;
  flex-direction: column;
  justify-content: ${y};
  align-items: ${x};
`;

export const flexAlignHorizontalContent = (x, y) => `
  display: flex;
  flex-direction: row;
  justify-content: ${x};
  align-items: ${y};
`;

export const backgroundImage = src => `background-image: url('${process.env.CVNA_APP_CDN_URL}${src}');`;

export const MOBILE = 'mobile';
export const LARGE_MOBILE = 'largeMobile';
export const DESKTOP = 'desktop';

export const breakpoints = {
  [MOBILE]: 'max-width: 540px',
  [LARGE_MOBILE]: 'max-width: 840px',
  [DESKTOP]: 'min-width: 840px'
};

export const customMediaQuery = (width, content) => `
  @media screen and (${width}) { ${Array.isArray(content) ? content.join('\n') : content} }
`;

export const mq = (width, content) => customMediaQuery(breakpoints[width] || width, content);
