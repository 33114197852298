import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSegmentTrack from '@src/hooks/useSegmentTrack';

const TrackedLink = ({
  children, segmentProps, query, variables, transformer, ...props
}) => {
  const anchor = useRef(null);
  const doSegmentTrack = useSegmentTrack('trackLink');

  useEffect(() => {
    doSegmentTrack(segmentProps?.event || `${props.href} Clicked`, {
      query,
      variables,
      transformer,
      element: anchor.current,
      props: {
        ...segmentProps.props,
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <a ref={ anchor } href={ props.href } { ...props }>{children}</a>;
};

TrackedLink.defaultProps = {
  segmentProps: {
    event: '',
    props: {}
  },
  query: null,
  variables: null,
  transformer: null
};

TrackedLink.propTypes = {
  children: PropTypes.node.isRequired,
  segmentProps: PropTypes.shape({
    event: PropTypes.string,
    props: PropTypes.shape({})
  }),
  href: PropTypes.string.isRequired,
  query: PropTypes.shape({}),
  variables: PropTypes.shape({}),
  transformer: PropTypes.func
};

export default TrackedLink;
