import PropTypes from 'prop-types';
import Primary from '../../../public/images/carvana_auction_logo_primary.svg';
import Secondary from '../../../public/images/carvana_auction_logo_secondary.svg';
import PrimaryIcon from '../../../public/images/carvana_auction_logo_primary_icon.svg';
import Clear from '../../../public/images/adesa_clear_logo_light.svg';

const variants = {
  primary: Primary,
  secondary: Secondary,
  primaryIcon: PrimaryIcon,
  clear: Clear
};

const AuctionLogo = ({ variant }) => {
  const Logo = variants[variant];
  return <Logo />;
};

AuctionLogo.defaultProps = {
  variant: 'primary'
};

AuctionLogo.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants))
};

export default AuctionLogo;
