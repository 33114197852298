import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { text } from '@carvana/helios-styles';
import Button from '@components/Button';
import useCid from '@hooks/useCid';
import useSaleEndsIn from '@hooks/useSaleEndsIn';
import useTimeoutModal from '@hooks/useTimeoutModal';

import { getColorFromState, getLabelFromState, getTimeRemaining, states } from '../utils';
import TimerText from '../TimerText';

const { UPCOMING, LIVE } = states;
const SaleTimer = ({ isLive }) => {
  const { auctionAlphaUsers } = useFlags();
  const { reload } = useRouter();
  const { TimeoutModal, openTimeoutModal } = useTimeoutModal();
  const [showTimer, setShowTimer] = useState(false);
  const { start: startOfTimer, end: endOfTimer, currentTime, clearInterval, restartTimer } = useSaleEndsIn(); // times in seconds
  const signInUrl = useCid(process.env.CARVANA_ACCESS_LOGIN_URL, 'APP-C4PWEB-SRPTIMEOUT');

  useEffect(() => {
    setShowTimer(true);
  }, []);

  useEffect(() => {
    restartTimer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLive]);

  useEffect(() => {
    if (!auctionAlphaUsers && currentTime >= endOfTimer) {
      openTimeoutModal();
    }
    if (auctionAlphaUsers && currentTime >= endOfTimer) {
      clearInterval();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, endOfTimer, auctionAlphaUsers, clearInterval]);

  return (
    <div>
      { showTimer && (
        <TimerText
          label={ getLabelFromState(isLive ? LIVE : UPCOMING) }
          color={ text[getColorFromState(isLive ? LIVE : UPCOMING)] }
          time={ getTimeRemaining({ startOfTimer, endOfTimer, currentTime }) }
        />
      )}
      { !auctionAlphaUsers && (
        <TimeoutModal
          headerText='This sale is now live!'
          titleText='Our listings are now live!'
          // eslint-disable-next-line max-len
          bodyText="These listings are no longer on the run list because they are now live for bidding through Manheim. Sign into CarvanaACCESS with your Manheim account to browse today's sale."
        >
          <Button
            design='primary'
            href={ signInUrl.href }
            target='_blank'
            segmentProps={{
              event: 'Manheim Sign In Clicked',
              props: {
                component: 'SRP Timeout'
              }
            }}
          >
            Sign In with Manheim
          </Button>
          <Button design='tertiary' onClick={ reload }>
            See Run List For Next Sale
          </Button>
        </TimeoutModal>
      )}
    </div>
  );
};

SaleTimer.propTypes = {
  isLive: PropTypes.bool
};

export default SaleTimer;
